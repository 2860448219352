import { combineReducers } from 'redux';
import userReducer from './userReducer';
import messageReducer from './messageReducer';
import ordersReducer from './ordersReducer';
import storesReducer from './storesReducer';
import paymentsReducer from './paymentsReducer';
import rolesReducer from './rolesReducer';
import loadingReducer from './loadingReducer';
import preorderReducer from './preorderReducer';
import stepsReducer from './stepsReducer';
import termsReducer from './termsReducer';
import dashboardReducer from './dashboardReducer';
import tokenRequiredReducer from './tokenRequiredReducer';
import redirectReducer from './redirectReducer';
import paymentLinkReducer from './paymentLinkReducer';
import locationReducer from './locationReducer';
import platformReducer from './platformReducer';

const appReducer = combineReducers({
  user: userReducer,
  message: messageReducer,
  orders: ordersReducer,
  merchant: storesReducer,
  payments: paymentsReducer,
  role: rolesReducer,
  loading: loadingReducer,
  platform: platformReducer,
  checkoutStep: stepsReducer,
  termsAccepted: termsReducer,
  redirectLink: redirectReducer,
  tokenRequired: tokenRequiredReducer,
  preorder: preorderReducer,
  dashboard: dashboardReducer,
  paymentLink: paymentLinkReducer,
  location: locationReducer,
});

export default appReducer;
