const initialState = {
  ms_token: '',
  vgs_token: '',
  loading_vgs_token: false,
};

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_MS_PAYMENTS_TOKEN':
      return {
        ...state,
        ms_token: action.payload.token,
      };
    case 'SET_MS_PAYMENTS_VGS_TOKEN':
      return {
        ...state,
        vgs_token: action.payload,
      };
    case 'SET_LOADING_VGS_TOKEN':
      return {
        ...state,
        loading_vgs_token: action.payload,
      };
    case 'RESET_PAYMENTS':
      return initialState;
    default:
      return state;
  }
}
