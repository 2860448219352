/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import appReducer from './reducers';

const initialState = {};
const middleware = [thunk];
const enhancers = [applyMiddleware(...middleware)];

if (process.env.NODE_ENV === 'development') {
  enhancers.push((window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose);
}

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = {
      ...initialState,
      ...JSON.parse(localStorage.getItem('state')),
    };
    return stateStr;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage();

const store = createStore(
  appReducer,
  persistedStore,
  compose(...enhancers),
);

store.subscribe(() => {
  saveToLocalStorage({
    user: store.getState().user,
    merchant: store.getState().merchant,
  });
});

export default store;
