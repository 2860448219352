/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef } from 'react';
import { styled } from '@mui/material';

const AuthInput = styled('input')(({ error }) => ({
  width: '50px',
  height: '60px',
  marginRight: '1rem',
  boxSizing: 'border-box',
  border: error ? '2px solid #B12323' : '2px solid #ccc',
  color: error ? '#B12323' : '#2B2D42',
  borderRadius: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '36px',
  lineHeight: '30px',
  textAlign: 'center',
  '&:focus': {
    outline: 'none',
    borderColor: '#191A1D',
  },
}));

const AuthCodeContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  margin: '1rem 0rem',
}));

function AuthCode({ onChange, error, isAuth }) {
  const inputsRef = useRef([]);
  const inputs = [];
  const codeRegex = /^[0-9]$/;

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join('');
    onChange(res);
  };

  const handleOnChange = (e) => {
    const { target: { value, nextElementSibling } } = e;
    if (value.length > 1) {
      e.target.value = value.charAt(0);
      if (nextElementSibling !== null) {
        (nextElementSibling).focus();
      }
    } else if (codeRegex.test(value)) {
      if (nextElementSibling !== null) {
        (nextElementSibling).focus();
      }
    } else {
      e.target.value = '';
    }
    sendResult();
  };

  const handleOnKeyDown = (e, type) => {
    const { key } = e;
    const { target } = e;
    const invalidNumberChars = ['e', 'E', ',', '.'];

    if (type === 'number' && invalidNumberChars.includes(e.key)) {
      e.preventDefault();
      target.value = '';
    } else if (key === 'Backspace') {
      if (target.value === '') {
        if (target.previousElementSibling !== null) {
          const t = target.previousElementSibling;
          t.value = '';
          t.focus();
          e.preventDefault();
        }
      } else {
        target.value = '';
      }
      sendResult();
    }
  };

  const handleOnFocus = (e) => {
    e.target.select();
  };

  const handleOnPaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text');
    let currentInput = 0;
    for (let i = 0; i < pastedValue.length; i++) {
      const pastedCharacter = pastedValue.charAt(i);
      const currentValue = inputsRef.current[currentInput].value;
      if (codeRegex.test(pastedCharacter)) {
        if (!currentValue) {
          inputsRef.current[currentInput].value = pastedCharacter;
          if (inputsRef.current[currentInput].nextElementSibling !== null) {
            (inputsRef.current[currentInput]
              .nextElementSibling).focus();
            currentInput++;
          }
        }
      }
    }
    sendResult();
    e.preventDefault();
  };

  for (let i = 0; i < 4; i++) {
    inputs.push(
      <AuthInput
        key={i}
        onChange={handleOnChange}
        onKeyDown={(e) => handleOnKeyDown(e)}
        onFocus={handleOnFocus}
        onPaste={handleOnPaste}
        inputMode="numeric"
        type="text"
        ref={(el) => {
          inputsRef.current[i] = el;
        }}
        autoFocus={i === 0}
        required
        maxLength={1}
        error={error}
        autoComplete={i === 0 ? 'one-time-code' : 'off'}
        disabled={isAuth}
      />,
    );
  }

  return (
    <AuthCodeContainer>
      {inputs}
    </AuthCodeContainer>
  );
}

export default AuthCode;
