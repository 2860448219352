import { Divider, Skeleton, Stack } from '@mui/material';
import React from 'react';
import {
  Container, Content, ContentContainer, Nav, SideItemSkeleton, SideNav, SideStack,
} from './skeletons.styled';

function DashSkeleton() {
  return (
    <Container>
      <Nav>
        <Skeleton
          variant="rounded"
          sx={{ visibility: { xs: 'hidden', sm: 'visible' }, borderRadius: '6rem' }}
          animation="wave"
          width={99}
          height={36}
        />
        <Skeleton
          variant="circular"
          animation="wave"
          width={40}
          height={40}
        />
      </Nav>
      <ContentContainer>
        <SideNav>
          <SideStack>
            <SideItemSkeleton
              variant="rounded"
              animation="wave"
            />
            <SideItemSkeleton
              variant="rounded"
              animation="wave"
            />
            <SideItemSkeleton
              variant="rounded"
              animation="wave"
            />
            <SideItemSkeleton
              variant="rounded"
              animation="wave"
            />
            <SideItemSkeleton
              variant="rounded"
              animation="wave"
            />
            <SideItemSkeleton
              variant="rounded"
              animation="wave"
            />
          </SideStack>
        </SideNav>
        <Content>
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{
              width: { xs: '8rem', sm: '10rem', md: '14rem' },
              height: '2rem',
            }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{
              width: { xs: '16rem', sm: '24rem', md: '30rem' },
              height: '3rem',
            }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{
              width: { xs: '12rem', sm: '20rem', md: '24rem' },
              height: '3rem',
            }}
          />
          <Stack direction="row" gap="2rem" sx={{ flexWrap: 'wrap' }}>
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{
                width: { xs: '16rem', sm: '20rem', md: '24rem' },
                height: '24rem',
              }}
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{
                width: { xs: '16rem', sm: '20rem', md: '24rem' },
                height: '24rem',
              }}
            />
          </Stack>
        </Content>
      </ContentContainer>
    </Container>
  );
}

export function CheckoutSkeleton() {
  return (
    <Container>
      <ContentContainer sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        height: '100%',
        gap: '1rem',
        justifyContent: 'center',
      }}
      >
        <Content sx={{ width: { xs: '100%', md: '32rem' }, gap: '1rem' }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '12rem', height: '4rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '50%', height: '2rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: '3rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: '3rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: '3rem' }}
          />
          <Stack direction="column" width="100%" gap={3} mt={2}>
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ width: '50%', height: '2rem' }}
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ width: '100%', height: '14rem' }}
            />
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{ width: '100%', height: '3rem', borderRadius: '6rem' }}
            />
          </Stack>
        </Content>
        <Divider sx={{ display: { xs: 'none', sm: 'flex' } }} orientation="vertical" />
        <Content
          sx={{
            display: { xs: 'none', sm: 'flex' },
            gap: '1.5rem',
            width: { xs: '100%', md: '32rem' },
          }}
        >
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '50%', height: '2rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: '6rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: '3rem' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '100%', height: '3rem' }}
          />
        </Content>
      </ContentContainer>
    </Container>
  );
}

export default function AppSkeleton({ path }) {
  if (path.includes('/payment')) {
    return <CheckoutSkeleton />;
  }
  return <DashSkeleton />;
}
