import {
  Grid, IconButton, Typography, styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import fbIcon from '../../../assets/dashboard/footer_fb_icon.svg';
import twIcon from '../../../assets/dashboard/footer_tw_icon.svg';
import wavaIcon from '../../../assets/dashboard/footer_wava_icon.svg';

const FooterContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  minHeight: '4rem',
}));

const FooterBar = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  maxHeight: '4.2rem',
  padding: '1.25rem 2rem 1.25rem 2rem',
  margin: '2rem',
  borderRadius: '62rem',
  backgroundColor: '#E1E4E3',
  textAlign: 'center',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '100%',
    margin: '2rem 1.25rem',
    borderRadius: '2rem',
    gap: '1.5rem',
  },
}));

const FooterStack = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
}));

function Footer() {
  const { t } = useTranslation(['dashboard']);
  return (
    <FooterContainer>
      <FooterBar>
        <FooterStack>
          <img src={wavaIcon} alt="" />
        </FooterStack>
        <FooterStack>
          <Typography variant="body2" sx={{ color: '#484848' }}>
            {t('dash-footer')}
          </Typography>
        </FooterStack>
        <FooterStack>
          <IconButton href="https://www.facebook.com/wava.co" target="_blank" rel="noopener noreferrer">
            <img src={fbIcon} alt="Facebook" />
          </IconButton>
          <IconButton href="https://twitter.com/WavaCheckout" target="_blank" rel="noopener noreferrer">
            <img src={twIcon} alt="Twitter" />
          </IconButton>
        </FooterStack>
      </FooterBar>
    </FooterContainer>
  );
}

export default Footer;
