import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress, Stack, IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TextInputStyled } from '../../StepsCheckout/stepscheckout.styled';
import { getStoreLinks } from '../../../redux/actions/storesActions';

function LinksToolbar(props) {
  const {
    // role,
    isAdmin,
  } = props;
  const { t } = useTranslation(['payment-link']);
  const dispatch = useDispatch();
  const loadingLinks = useSelector((state) => state.merchant.loadingLinks);

  const [storeToSearch, setStoreToSearch] = useState('');

  const handleLinksByAdmin = () => {
    dispatch(getStoreLinks(storeToSearch));
  };

  return (
    <Toolbar
      sx={{
        justifyContent: 'space-between',
        mt: 0.5,
        mb: 0.5,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t('links-list-title')}
        </Typography>
        {loadingLinks && <CircularProgress />}
      </Stack>
      <TextInputStyled
        sx={isAdmin ? { display: 'flex' } : { display: 'none' }}
        type="number"
        size="small"
        label={t('links-admin-input-label')}
        placeholder={t('links-admin-input-ph')}
        value={storeToSearch}
        onChange={(e) => setStoreToSearch(e.target.value)}
        InputLabelProps={{ shrink: true }}
        disabled={loadingLinks}
        InputProps={{
          endAdornment: (
            <IconButton
              size="small"
              disabled={storeToSearch === ''}
              onClick={() => handleLinksByAdmin()}
            >
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </Toolbar>
  );
}

export default LinksToolbar;
