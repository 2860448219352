import { LoadingButton } from '@mui/lab';
import {
  TextField,
  Switch,
  alpha,
  styled,
  Grid,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';

export const StepContinueButton = styled(LoadingButton)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  textTransform: 'none',
  width: '100%',
  height: '3rem',
  borderRadius: '100px',
}));

export const SwitchStyled = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: '#022219',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#9DEA6C',
    '&:hover': {
      backgroundColor: alpha('#022219', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#022219',
  },
  '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
    color: '#9DEA6C',
  },
  '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: alpha('#022219', 0.5),
  },
}));

export const FormStyled = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const TextInputStyled = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CF2C2C',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#B12323',
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: '#484848',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#191A1D',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    color: '#484848',
  },
  '& .MuiInputLabel-asterisk': {
    color: 'red',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D9D9D9',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#A4A4A4',
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    borderColor: '#D9D9D9',
    backgroundColor: '#F0F2F1',
    '&:hover fieldset': {
      borderColor: '#D9D9D9',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#191A1D',
    borderRadius: '8px',
    borderWidth: '1.5px',
  },
  '& :-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px rgba(255,255,255) inset',
  },
}));

export const SelectInputStyled = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CF2C2C',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#B12323',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    color: '#484848',
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: '#B12323',
  },
  display: 'flex',
  flex: 1,
  width: '100%',
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#191A1D',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D9D9D9',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#A4A4A4',
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    borderColor: '#D9D9D9',
    backgroundColor: '#F0F2F1',
    '&:hover fieldset': {
      borderColor: '#D9D9D9',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#191A1D',
    borderRadius: '8px',
    borderWidth: '1.5px',
  },
}));

export const CountrySelect = styled('select')(() => ({
  width: '1rem',
  height: '2.5rem',
  borderRadius: '20px',
  border: 'none',
  outline: '0px',
  cursor: 'pointer',
}));

export const StoreLogoContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1rem',
  width: '6rem',
  height: '6rem',
  border: '1px solid #D9D9D9',
}));

export const StoreLogoImage = styled('img')(() => ({
  aspectRatio: '3/2',
  objectFit: 'contain',
}));

// commented bgColor is ok, but it affects the outlined variant
export const ButtonStyled = styled(Button)(() => ({
  // backgroundColor: '#022219',
  borderRadius: '6.25rem',
  textTransform: 'none',
}));

export const ReturnButton = styled(Button)(() => ({
  position: 'absolute',
  top: '2.5rem',
  gap: 4,
  textTransform: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '@media (max-width: 768px)': {
    top: '1.5rem',
    padding: 0,
  },
}));

export const DialogStyled = styled(Dialog)(() => ({
  background: 'rgba(22, 28, 36, 0.48)',
}));

export const DialogContentStyled = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'scroll',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  minWidth: '23rem',
  maxWidth: '23rem',
  padding: '1rem 2rem 2rem 2rem',
  gap: '2rem',
  '@media (max-width: 440px)': {
    minWidth: '20rem',
    maxWidth: '20rem',
    gap: '1rem',
    padding: '0.5rem 1.25rem 1.25rem 1.25rem',
  },
  '@media (max-width: 370px)': {
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
  },
}));
