const initialState = {
  status: false,
  tokenError: false,
};

export default function tokenRequiredReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_TOKEN_REQUIRED':
      return {
        ...state,
        status: action.payload,
      };
    case 'SET_TOKEN_ERROR':
      return {
        ...state,
        tokenError: action.payload,
      };
    default: return state;
  }
}
