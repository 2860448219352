import React from 'react';
import {
  Page, Text, View, Document, StyleSheet, Svg, Defs, LinearGradient, Stop, Rect, Image,
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import pdfLogo from '../../../assets/logo_pdf.png';
import { fCurrency } from '../../../utils/formatNumber';

const styles = StyleSheet.create({
  page: {
    color: '#022219',
    fontFamily: 'Helvetica',
  },
  banner: {
    position: 'absolute',
    top: '0',
    height: '10px',
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#919EAB',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '32px 26px',
    gap: '24px',
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: '8px',
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '18px',
  },
  subtitle: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: '12px',
  },
  footerText: {
    fontFamily: 'Helvetica',
    fontSize: '8px',
  },
});

function OrdersPDF({ order }) {
  const { t } = useTranslation(['orders']);
  const idOrder = order?.id_order;
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const fLocaleDate = (date) => new Date(date)?.toLocaleDateString('es-ES', dateOptions) || '-';

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.banner}>
          <Svg viewBox="0 0 0 0" width="600" height="10px">
            <Defs>
              <LinearGradient id="myLinearGradient" x1="0" x2="1.3" y1="0" y2="0">
                <Stop offset="50%" stopColor="#022219" />
                <Stop offset="50%" stopColor="#9DEA6C" />
              </LinearGradient>
            </Defs>
            <Rect fill="url('#myLinearGradient')" x="0" y="0" width="600" height="10px" />
          </Svg>
        </View>
        <View style={styles.pageWrapper}>
          <View style={[styles.sectionContainer, { marginBottom: '18px' }]}>
            <Text style={styles.title}>{t('pdf-title')}</Text>
            <Image src={pdfLogo} style={{ width: '130px' }} alt="Wava" />
          </View>
          <View style={styles.section}>
            <View style={styles.textContainer}>
              <Text style={styles.subtitle}>{t('pdf-ref')}</Text>
              <Text style={styles.text}>{t('pdf-order-num', { idOrder })}</Text>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.subtitle}>{t('pdf-emission-date')}</Text>
              <Text style={styles.text}>{fLocaleDate(order?.purchase_date) || '-'}</Text>
            </View>
          </View>
          <View style={styles.sectionContainer}>
            <View style={styles.section}>
              <Text style={styles.subtitle}>Wava Technologies Colombia S.A.S</Text>
              <Text style={styles.text}>Carrera 14 # 93-68, Oficina 601</Text>
              <Text style={styles.text}>Bogotá, Colombia</Text>
              <Text style={styles.text}>Colombia +57(320)306-6409</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.subtitle}>{t('pdf-charged-to')}</Text>
              <Text style={styles.text}>{`${order?.shopper?.first_name} ${order?.shopper?.last_name}` || ''}</Text>
              <Text style={styles.text}>{order?.shopper?.email || ''}</Text>
              <Text style={styles.text}>{order?.shopper?.phone_number || ''}</Text>
            </View>
          </View>
          <View style={[styles.section, { margin: '50px 0px' }]}>
            <View style={styles.textContainer}>
              <Text style={[styles.subtitle, { minWidth: '250px' }]}>{t('pdf-description')}</Text>
              <Text style={[styles.subtitle, { minWidth: '100px' }]}>{t('pdf-qty')}</Text>
              <Text style={[styles.subtitle, { flexGrow: 1 }]}>{t('pdf-amount')}</Text>
              <Text style={[styles.subtitle, { textAlign: 'right' }]}>{t('pdf-total')}</Text>
            </View>
            <View style={styles.divider} />
            {order?.store?.products.map((product) => (
              <View key={Math.random()} style={styles.textContainer}>
                <Text style={[styles.text, { minWidth: '250px' }]}>{product.name || '-'}</Text>
                <Text style={[styles.text, { minWidth: '100px' }]}>{product.quantity || '-'}</Text>
                <Text style={[styles.text, { flexGrow: 1 }]}>{fCurrency(product.current_price) || '-'}</Text>
                <Text style={[styles.text, { textAlign: 'right' }]}>{fCurrency(product.total_price) || '-'}</Text>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <View style={[styles.textContainer, { justifyContent: 'space-between' }]}>
              <Text style={styles.text}>{t('pdf-subtotal')}</Text>
              <Text style={styles.text}>{fCurrency(order?.subtotal_price) || '-'}</Text>
            </View>
            <View style={styles.divider} />
            <View style={[styles.textContainer, { justifyContent: 'space-between' }]}>
              <Text style={styles.text}>{t('pdf-discount')}</Text>
              <Text style={styles.text}>{`${order?.total_discount}%`}</Text>
            </View>
            <View style={styles.divider} />
            <View style={[styles.textContainer, { justifyContent: 'space-between' }]}>
              <Text style={styles.text}>{t('pdf-total-price')}</Text>
              <Text style={styles.text}>{fCurrency(order?.total_price) || '-'}</Text>
            </View>
          </View>
          {/* <View style={[styles.section, { flexGrow: 1, justifyContent: 'flex-end' }]}>
            <View style={styles.divider} />
            <Text style={styles.footerText}>{t('pdf-footer')}</Text>
          </View> */}
        </View>
      </Page>
    </Document>
  );
}

export default OrdersPDF;
