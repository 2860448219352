import { api } from '../../utils/api';
import { userData } from './userActions';

export const login = (data, navigate, errorMsg) => (dispatch) => {
  dispatch({ type: 'SET_LOADING_TRUE' });
  return api({
    url: 'auth/login',
    data,
    successHandler: () => {
      dispatch({ type: 'SET_LOADING_FALSE' });
      dispatch({
        type: 'SET_USER',
        payload: { email: data.email },
      });
      navigate('/code');
    },
    errorHandler: (error) => {
      dispatch({ type: 'SET_LOADING_FALSE' });
      if (error.response.status === 404) {
        navigate('/signup');
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            text: errorMsg || error?.response?.data?.result?.message,
            kind: 'error',
          },
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            text: error?.response?.data?.result?.message,
            kind: 'error',
          },
        });
      }
    },
  });
};

export const loginCode = (data, message, navigate) => (dispatch) => {
  dispatch({ type: 'SET_LOADING_TRUE' });
  api({
    url: 'auth/login',
    data,
    method: 'POST',
    successHandler: (res) => {
      if (res?.result?.token) {
        const currentDate = new Date();
        const nextWeekDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        document.cookie = `wavaAuth=true; expires=${nextWeekDate.toUTCString()}`;
      }
      dispatch(userData(navigate));
    },
    errorHandler: () => {
      dispatch({ type: 'SET_LOADING_FALSE' });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: message.msg_error, kind: 'error' },
      });
    },
  });
};

export const loginResendCode = (data, message) => (dispatch) => api({
  url: 'auth/login',
  data,
  successHandler: () => {
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: message.msg_positive, kind: 'success' },
    });
  },
  errorHandler: () => {
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: message.msg_negative, kind: 'error' },
    });
  },
});

// Log user out
export const logout = (navigate) => (dispatch) => api({
  url: 'auth/logout',
  successHandler: () => {
    // Remove token cookie
    localStorage.removeItem('state');
    sessionStorage.removeItem('state');
    document.cookie = 'mspayToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = 'vgsToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = 'wavaAuth=;expires=Thu, 01 Jan 1970 00:00:01 GMT';

    // Set initial values keeping locations with current data.
    dispatch({
      type: 'SET_LOADING_FALSE',
    });
    dispatch({
      type: 'RESET_ORDERS',
    });
    dispatch({
      type: 'RESET_PREORDER',
    });
    dispatch({
      type: 'RESET_PAYMENTS',
    });
    dispatch({
      type: 'RESET_ROLE',
    });
    dispatch({
      type: 'RESET_SHOP',
    });
    dispatch({
      type: 'STORE_LOGOUT',
    });
    dispatch({
      type: 'RESET_USER',
    });
    dispatch({
      type: 'SET_USER_AUTH',
      payload: false,
    });
    dispatch({
      type: 'RESET_LOCATION',
    });
    navigate('/login');
  },
  errorHandler: () => { },
});
