import {
  Button,
  Grid, Stack, styled,
} from '@mui/material';

export const OrdersContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  minHeight: '100vh',
}));

export const FiltersContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
}));

export const FilterStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  padding: '1rem',
  gap: '1rem',
}));

export const FilterGrid = styled(Stack)(() => ({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  minWidth: '20rem',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
}));

export const FilterButton = styled(Button)(({ selected, sum, afterid }) => ({
  color: selected ? '#0F172A' : '#334155',
  fontWeight: selected ? 600 : 400,
  borderRadius: '0px',
  borderBottom: selected ? '2px solid black' : '2px solid transparent',
  ':hover': {
    backgroundColor: 'transparent',
  },
  ':after': {
    content: `"${sum}"`,
    marginLeft: '0.5rem',
    padding: '2px 8px',
    backgroundColor: (afterid === 1 && '#022219')
    || (afterid === 2 && '#E3FAD4')
    || (afterid === 3 && '#FAF0D4')
    || (afterid === 4 && '#00B8D9'),
    color: (afterid === 1 && '#9DEA6C')
    || (afterid === 2 && '#387A0C')
    || (afterid === 3 && '#B77D03')
    || (afterid === 4 && '#006C9C'),
    borderRadius: '6px',
  },
}));
