import React from 'react';
import { useTranslation } from 'react-i18next';
import walletIcon from '../../../assets/dashboard/card_wallet.svg';
import { NoDataStack, TypographyStyled } from '../dashboard.styled';

function NoTransactions({ text }) {
  const { t } = useTranslation(['dashboard']);

  return (
    <NoDataStack>
      <img src={walletIcon} alt="" />
      <TypographyStyled variant="body2">
        {text || t('no-transactions-yet')}
      </TypographyStyled>
    </NoDataStack>
  );
}

export default NoTransactions;
