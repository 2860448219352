import { memo } from 'react';
import { Box } from '@mui/material';

function CheckCircleIcon({
  width, height, fill, ...other
}) {
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
        <path d="M37.375 20.6976C37.375 24.0352 36.3853 27.2978 34.5311 30.0729C32.6768 32.848 30.0413 35.0109 26.9578 36.2881C23.8743 37.5653 20.4813 37.8995 17.2079 37.2484C13.9344 36.5973 10.9276 34.9901 8.56758 32.6301C6.20757 30.2701 4.60038 27.2632 3.94926 23.9898C3.29813 20.7164 3.63231 17.3234 4.90954 14.2398C6.18677 11.1563 8.34968 8.52083 11.1248 6.66658C13.8998 4.81233 17.1624 3.82263 20.5 3.82263C24.974 3.82759 29.2634 5.60708 32.427 8.77069C35.5906 11.9343 37.37 16.2236 37.375 20.6976Z" fill="#9DEA6C" />
        <path d="M28.6145 16.1614C28.5199 15.9334 28.3812 15.7265 28.2064 15.5523C28.0325 15.3776 27.826 15.239 27.5985 15.1442C27.3711 15.0494 27.1272 15.0004 26.8808 15C26.6344 14.9996 26.3903 15.0477 26.1625 15.1417C25.9347 15.2356 25.7277 15.3735 25.5533 15.5476L18.1298 22.9726L15.7064 20.5523C15.532 20.3778 15.3249 20.2395 15.097 20.1451C14.8691 20.0507 14.6249 20.0021 14.3783 20.0021C14.1316 20.0021 13.8874 20.0507 13.6595 20.1451C13.4316 20.2395 13.2245 20.3778 13.0501 20.5523C12.8757 20.7267 12.7374 20.9337 12.643 21.1616C12.5486 21.3895 12.5 21.6337 12.5 21.8804C12.5 22.127 12.5486 22.3713 12.643 22.5992C12.7374 22.827 12.8757 23.0341 13.0501 23.2085L16.8001 26.9585C16.9743 27.1333 17.1813 27.272 17.4092 27.3666C17.6371 27.4613 17.8815 27.51 18.1283 27.51C18.375 27.51 18.6194 27.4613 18.8473 27.3666C19.0752 27.272 19.2822 27.1333 19.4564 26.9585L28.2064 18.2085C28.3812 18.0343 28.5199 17.8273 28.6145 17.5994C28.7091 17.3715 28.7579 17.1272 28.7579 16.8804C28.7579 16.6336 28.7091 16.3893 28.6145 16.1614Z" fill="#022219" />
      </svg>
    </Box>
  );
}

export default memo(CheckCircleIcon);
