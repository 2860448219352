/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConfigCta, ConfigLoadingCta, PanelContainer, PanelSection,
} from '../config.styled';
import { SwitchStyled } from '../../../StepsCheckout/stepscheckout.styled';
import { editStoreConfig } from '../../../../redux/actions/storesActions';

function NotificationSwtich({
  active, switchState, switchAction, text,
}) {
  return (
    <Stack direction="row" gap={2} alignItems="center" mb={2}>
      <SwitchStyled
        checked={switchState}
        disabled={!active}
        onChange={() => switchAction(!switchState)}
      />
      <Typography variant="body2" color={active ? 'primary' : 'text.disabled'}>
        {text}
      </Typography>
    </Stack>
  );
}

function Notifications() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['configuration']);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const loadingStores = useSelector((state) => state.merchant.loadingStores);
  const storeConfig = selectedStore?.config;
  const configArr = storeConfig ? Object.keys(storeConfig) : [];

  const emailConfirm = configArr?.includes('confirm_order_email');
  const emailCancel = configArr?.includes('cancel_order_email');
  const emailMembersConfirm = configArr?.includes('confirm_order_members_email');
  const emailMembersCancel = configArr?.includes('cancel_order_members_email');

  const [emailConfirmSwitch, setEmailConfirmSwitch] = useState(storeConfig?.confirm_order_email);
  const [emailCancelSwitch, setEmailCancelSwitch] = useState(storeConfig?.cancel_order_email);
  const [membersConfirmSwitch, setMembersConfirmSwitch] = useState(storeConfig?.confirm_order_members_email);
  const [membersCancelSwitch, setMembersCancelSwitch] = useState(storeConfig?.cancel_order_members_email);

  const switchCompare = emailConfirmSwitch !== storeConfig?.confirm_order_email
    || emailCancelSwitch !== storeConfig?.cancel_order_email
    || membersConfirmSwitch !== storeConfig?.confirm_order_members_email
    || membersCancelSwitch !== storeConfig?.cancel_order_members_email;

  const handleCancelChanges = () => {
    setEmailConfirmSwitch(storeConfig?.confirm_order_email);
    setEmailCancelSwitch(storeConfig?.cancel_order_email);
    setMembersConfirmSwitch(storeConfig?.confirm_order_members_email);
    setMembersCancelSwitch(storeConfig?.cancel_order_members_email);
  };

  useEffect(() => {
    setEmailConfirmSwitch(storeConfig?.confirm_order_email);
    setEmailCancelSwitch(storeConfig?.cancel_order_email);
    setMembersConfirmSwitch(storeConfig?.confirm_order_members_email);
    setMembersCancelSwitch(storeConfig?.cancel_order_members_email);
  }, [selectedStore]);

  const handleSaveChanges = () => {
    dispatch(editStoreConfig(
      selectedStore?.id_store,
      {
        config: {
          confirm_order_email: emailConfirmSwitch,
          cancel_order_email: emailCancelSwitch,
          confirm_order_members_email: membersConfirmSwitch,
          cancel_order_members_email: membersCancelSwitch,
        },
      },
      {
        success: t('config-notif-saved'),
        error: t('config-edit-error'),
      },
    ));
  };

  return (
    <PanelContainer>
      <PanelSection active={1} border sx={{ paddingTop: 2 }}>
        <Typography variant="h6">
          {t('config-tab-notifications-title')}
        </Typography>
        <Typography variant="p">
          {t('config-tab-notifications-desc')}
        </Typography>
        <NotificationSwtich
          active={emailConfirm}
          switchState={emailConfirmSwitch}
          switchAction={setEmailConfirmSwitch}
          text={t('notif-email-confirm')}
        />
        <NotificationSwtich
          active={emailCancel}
          switchState={emailCancelSwitch}
          switchAction={setEmailCancelSwitch}
          text={t('notif-email-cancel')}
        />
        <NotificationSwtich
          active={emailMembersConfirm}
          switchState={membersConfirmSwitch}
          switchAction={setMembersConfirmSwitch}
          text={t('notif-email-members-confirm')}
        />
        <NotificationSwtich
          active={emailMembersCancel}
          switchState={membersCancelSwitch}
          switchAction={setMembersCancelSwitch}
          text={t('notif-email-members-cancel')}
        />
        <Stack direction="row" gap={2}>
          <ConfigCta
            disabled={!switchCompare}
            variant="outlined"
            onClick={() => handleCancelChanges()}
          >
            {t('config-cancel')}
          </ConfigCta>
          <ConfigLoadingCta
            disabled={!switchCompare}
            variant="contained"
            onClick={() => handleSaveChanges()}
            loading={loadingStores}
          >
            {t('config-save')}
          </ConfigLoadingCta>
        </Stack>
      </PanelSection>
    </PanelContainer>
  );
}

export default Notifications;
