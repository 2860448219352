const initialState = {
  isAuthenticated: false,
  email: '',
  id_user: 0,
  first_name: '',
  last_name: '',
  username: null,
  is_admin: 0,
  national_id_number: 0,
  phone_number: '',
  id_national_document_type: '',
  addresses: {},
  payment_methods: [{
    credit_cards: [],
  }],
  allowedDocuments: [],
  loadingUserData: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_USER_DATA':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_LOADING_USER_DATA':
      return {
        ...state,
        loadingUserData: action.payload,
      };
    case 'SET_USER_AUTH':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'SET_USER_RESET_CC':
      return {
        ...state,
        payment_methods: [{
          credit_cards: [],
        }],
      };
    case 'SET_ALLOWED_DOCUMENTS':
      return {
        ...state,
        allowedDocuments: action.payload,
      };
    case 'RESET_USER':
      return initialState;
    default:
      return state;
  }
}
