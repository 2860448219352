const initialState = {
  path: '',
  onboardCode: '',
  referralCode: '',
  storeId: null,
  orderId: null,
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOCATION':
      return {
        ...state,
        path: action.payload,
      };
    case 'SET_ONBOARD_CODE':
      return {
        ...state,
        onboardCode: action.payload,
      };
    case 'SET_ONBOARD_REFCODE':
      return {
        ...state,
        referralCode: action.payload,
      };
    case 'SET_STORE_ID':
      return {
        ...state,
        storeId: action.payload,
      };
    case 'SET_ORDER_ID':
      return {
        ...state,
        orderId: action.payload,
      };
    case 'RESET_LOCATION':
      return {
        ...state,
        path: '',
      };
    default:
      return state;
  }
}
