import { api, apiGet } from '../../utils/api';
// import { userData } from './userActions';

export const orders = (data) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_LOADING',
    payload: true,
  });
  return api({
    url: 'orders',
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER',
        payload: successData?.result,
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      if (error.response?.data?.result?.message === 'Store inactive') {
        dispatch({
          type: 'SET_INACTIVE_STORE',
          payload: true,
        });
      } else {
        dispatch({
          type: 'SET_INACTIVE_STORE',
          payload: false,
        });
      }
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
  });
};

export const placeOrder = (orderId, data, localization, gatewayName) => (dispatch) => {
  dispatch({
    type: 'PLACE_ORDER',
    payload: true,
  });
  return api({
    url: `orders/place/${orderId}`,
    data,
    successHandler: (successData) => {
      dispatch({
        type: 'SET_ORDER_CONFIRMED',
        payload: successData?.result,
      });
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
      if (successData?.result?.token_required) {
        dispatch({
          type: 'SET_TOKEN_REQUIRED',
          payload: true,
        });
      } else if (gatewayName === 'Nequi') {
        dispatch({
          type: 'SET_CHECKOUT_STEP',
          payload: 6,
        });
      } else {
        dispatch({
          type: 'SET_CHECKOUT_STEP',
          payload: 5,
        });
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_success || 'Order Processed!', kind: 'success' },
        });
      }
    },
    errorHandler: (error) => {
      if (error === 'Invalid Confirmation token') {
        dispatch({
          type: 'SET_TOKEN_ERROR',
          payload: true,
        });
        dispatch({
          type: 'SET_ORDER_FAILED',
          payload: data?.result,
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error?.response?.data?.result?.message, kind: 'error' },
        });
        dispatch({
          type: 'SET_ORDER_FAILED',
          payload: data?.result,
        });
      }
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
    },
  });
};

export const getOrderStatus = (orderId, localization, failureModal) => (dispatch) => {
  dispatch({
    type: 'PLACE_ORDER',
    payload: true,
  });
  return apiGet({
    url: `orders/${orderId}`,
    successHandler: (successData) => {
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
      if (successData?.result?.status === 'confirmed') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_success || 'Order Processed!', kind: 'success' },
        });
        dispatch({
          type: 'SET_ORDER_CONFIRMED',
          payload: successData?.result,
        });
        dispatch({
          type: 'SET_CHECKOUT_STEP',
          payload: 5,
        });
      } else if (successData?.result?.status === 'pending') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_pending || 'Payment pending.', kind: 'warning' },
        });
      } else if (successData?.result?.status === 'processing') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: localization.msg_processing || 'Your payment is being processed.', kind: 'warning' },
        });
      } else if (successData?.result?.status === 'cancelled') {
        failureModal(true);
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'Unhandled Error', kind: 'error' },
        });
      }
    },
    errorHandler: (error) => {
      failureModal(true);
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error?.response?.data?.result?.message, kind: 'error' },
      });
      dispatch({
        type: 'PLACE_ORDER',
        payload: false,
      });
    },
  });
};

export const getOrders = (storeId = '', range) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_LOADING',
    payload: true,
  });
  return apiGet({
    url: `orders${storeId
      ? (range
        ? `/merchant/${storeId}?date_from=${range.from}&date_to=${range.to}&limit=100&sort=purchase_date`
        : `/merchant/${storeId}?limit=100&sort=purchase_date`)
      : ''}`,
    successHandler: (data) => {
      if (data) {
        dispatch({
          type: 'SET_ORDERS_LIST',
          payload: data?.result,
        });
        dispatch({
          type: 'SET_FILTERED_ORDERS',
          payload: data?.result,
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'No orders available', kind: 'error' },
        });
      }
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error?.result, kind: 'error' },
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
  });
};

// export const getCheckoutOrder = (localization, navigate, reduxLocations) => (dispatch) => {
//   dispatch({
//     type: 'SET_ORDER_LOADING',
//     payload: true,
//   });
//   return apiGet({
//     url: 'orders?status=pending',
//     successHandler: (data) => {
//       if (data?.result?.length === 0) {
//         if (Object.keys(reduxLocations).length > 0) {
//           dispatch({
//             type: 'SET_MESSAGE',
//             payload: { text: localization.msg_error || 'No open orders', kind: 'error' },
//           });
//         }
//         // navigate('/');
//       }
//       dispatch({
//         type: 'SET_ORDER',
//         payload: data?.result[0],
//       });
//       dispatch({
//         type: 'SET_ORDER_LOADING',
//         payload: false,
//       });
//     },
//     errorHandler: () => {
//       // dispatch({
//       //   type: "SET_MESSAGE",
//       //   payload: { text: error?.result, kind: "error" },
//       // });
//       dispatch({
//         type: 'SET_ORDER_LOADING',
//         payload: false,
//       });
//     },
//   });
// };

export const getOrdersByStore = (storeId = '') => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_LOADING',
    payload: true,
  });
  return apiGet({
    url: `orders/merchant/${storeId}`,
    successHandler: (data) => {
      dispatch({
        type: 'SET_STORE_ORDERS',
        payload: data?.result,
        storeId,
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error.result, kind: 'error' },
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
      dispatch({
        type: 'SET_ORDERS_LIST',
        payload: [],
      });
    },
  });
};

export const refundOrder = (orderId, storeId) => (dispatch) => {
  dispatch({
    type: 'SET_ORDER_LOADING',
    payload: true,
  });
  return api({
    url: `orders/refund/${orderId}`,
    successHandler: () => {
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
      dispatch(getOrdersByStore(storeId));
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Order refunded', kind: 'success' },
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error?.result, kind: 'error' },
      });
      dispatch({
        type: 'SET_ORDER_LOADING',
        payload: false,
      });
    },
  });
};
