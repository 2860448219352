import { LoadingButton } from '@mui/lab';
import {
  AccordionSummary,
  Box,
  Button,
  Grid, Stack, styled,
} from '@mui/material';

export const ConfigContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  padding: '1rem',
  gap: '1.3rem',
}));

export const ConfigStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minHeight: 'min-content',
  gap: '0.5rem',
}));

export const TabsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: '#F4F6F8',
  borderRadius: '0.5rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  minHeight: '3.58rem',
}));

export const PanelContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
}));

export const PanelSection = styled(Stack)(({ active, border }) => ({
  display: active ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  gap: '1.5rem',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  borderBottom: border ? '1px solid #E1E4E3' : '',
}));

export const ConfigCta = styled(Button)(() => ({
  borderRadius: '6rem',
  textTransform: 'none',
  minWidth: '6rem',
  maxHeight: '3rem',
}));

export const ConfigLoadingCta = styled(LoadingButton)(() => ({
  borderRadius: '6rem',
  textTransform: 'none',
  minWidth: '6rem',
  maxHeight: '3rem',
}));

export const LogoContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '1rem',
  width: '6rem',
  height: '6rem',
  border: '1px solid #D9D9D9',
}));

export const LogoImage = styled('img')(() => ({
  aspectRatio: '3/2',
  objectFit: 'contain',
}));

export const AccordionSummaryConfig = styled(AccordionSummary)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  '.MuiAccordionSummary-content': {
    gap: '1rem',
  },
}));

export const IntCardContainer = styled(Grid)(({ active }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  gap: '1rem',
  backgroundColor: active ? '#D8F6C41A' : 'transparent',
  border: '1px solid',
  borderRadius: '1rem',
  borderColor: active ? '#9DEA6C' : '#D9D9D9',
  padding: '2rem',
  width: '100%',
  maxWidth: '24rem',
  transition: '0.2s ease-in-out',
}));

export const IntActive = styled(Grid)(({ hasint, active }) => ({
  width: 'min-content',
  alignSelf: 'flex-end',
  padding: '2px 10px',
  borderRadius: '6px',
  opacity: hasint ? 1 : 0,
  backgroundColor: active ? '#022219' : '#D9D9D9',
  color: active ? '#9DEA6C' : '#191A1D',
}));

export const IntegrationLogo = styled('img')(() => ({
  width: '5rem',
  height: '48px',
  // aspectRatio: '3/2',
  objectFit: 'contain',
}));

export const InfoCardContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  backgroundColor: '#A5E0FE',
  borderRadius: '0.5rem',
  padding: '1rem 2rem',
  width: 'fit-content',
  minHeight: '3.5rem',
}));

export const TiendaNubeImage = styled('img')(() => ({
  maxWidth: '10rem',
  aspectRatio: '3/2',
  objectFit: 'contain',
  '@media (max-width: 400px)': {
    maxWidth: '8rem',
  },
}));
