const initialState = {
  shopper: {},
  payment_method: {},
  shipping_address: {},
  store: {
    products: [],
    shipping_methods: null,
    id_shipping_method_external: null,
  },
  shipment_cost: null,
  total_price: null,
  totalItems: null,
  total_discount: null,
  subtotal_price: null,
  registeredUser: false,
  isFreeShipping: false,
  coupons: [],
  save_user_data: false,
};

export default function preorderReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PREORDER_DATA':
      return {
        ...state,
        shopper: action.payload.shopper,
        payment_method: action.payload.payment_method,
        shipping_address: action.payload.shipping_address,
        store: {
          ...state.store,
          ...action.payload.storeData,
          products: action.payload.products,
        },
        shipment_cost: action.payload.shipment_cost,
        total_price: action.payload.total_price,
        totalItems: action.payload.totalItems,
        total_discount: action.payload.total_discount,
        subtotal_price: action.payload.subtotal_price,
        registeredUser: action.payload.registeredUser,
        isFreeShipping: action.payload.isFreeShipping,
        coupons: action.payload.coupons,
        isDigital: action.payload.isDigital,
      };
    case 'SET_PREORDER_TOTALS':
      return {
        ...state,
        shipment_cost: action.payload.shipment_cost,
        total_price: action.payload.total_price,
        totalItems: action.payload.totalItems,
        total_discount: action.payload.total_discount,
        subtotal_price: action.payload.subtotal_price,
        registeredUser: action.payload.registeredUser,
      };
    case 'SET_PREORDER_SHOPPER':
      return {
        ...state,
        shopper: {
          ...state.shopper,
          ...action.payload,
        },
      };
    case 'SET_PREORDER_SHOPPER_ADDRESS':
      return {
        ...state,
        shopper: {
          ...state.shopper,
          addresses: {
            ...action.payload,
          },
        },
      };
    case 'SET_PREORDER_ADDRESS':
      return {
        ...state,
        shipping_address: {
          ...action.payload,
        },
      };
    case 'SET_PREORDER_PAYMENT':
      return {
        ...state,
        payment_method: {
          ...action.payload,
        },
      };
    case 'SET_PREORDER_PAYMENT_RESET':
      return {
        ...state,
        payment_method: {},
      };
    case 'SET_PREORDER_STORE':
      return {
        ...state,
        store: {
          ...state.store,
          ...action.payload.storeData,
        },
      };
    case 'SET_PREORDER_PRODUCTS':
      return {
        ...state,
        store: {
          ...state.store,
          products: action.payload,
        },
      };
    case 'SET_PREORDER_SHIPPING':
      return {
        ...state,
        store: {
          ...state.store,
          shipping_methods: [{ ...action.payload.shippingMethod }],
          id_shipping_method_external: action.payload.idExternal,
        },
      };
    case 'SET_PREORDER_FREESHIPPING':
      return {
        ...state,
        isFreeShipping: action.payload,
      };
    case 'SET_PREORDER_COUPONS':
      return {
        ...state,
        coupons: action.payload,
      };
    case 'SET_SAVE_USER_DATA':
      return {
        ...state,
        save_user_data: action.payload,
      };
    case 'RESET_PREORDER':
      return initialState;
    default:
      return state;
  }
}
