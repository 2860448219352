import { api, apiGet, apiPut } from '../../utils/api';
import { getStores } from './storesActions';

export const userData = (navigate) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_USER_DATA',
    payload: true,
  });
  return apiGet({
    url: 'users',
    successHandler: (data) => {
      dispatch({
        type: 'SET_USER_AUTH',
        payload: true,
      });
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          ...data?.result,
        },
      });
      dispatch(getStores({ navigate }));
      dispatch({ type: 'SET_LOADING_FALSE' });
      dispatch({
        type: 'SET_LOADING_USER_DATA',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_USER_DATA',
        payload: false,
      });
    },
  });
};

export const register = (data, navigate) => (dispatch) => {
  dispatch({ type: 'SET_LOADING_TRUE' });
  api({
    url: 'users',
    data,
    successHandler: () => {
      dispatch({ type: 'SET_LOADING_FALSE' });
      dispatch({
        type: 'SET_USER',
        payload: {
          email: data.email,
        },
      });
      navigate('/code');
    },
    errorHandler: (error) => {
      dispatch({ type: 'SET_LOADING_FALSE' });
      if (error?.response?.data?.result === 'Duplicate user') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'Este email ya está registrado', kind: 'error' },
        });
        navigate('/login', { state: { e: data.email } });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error?.response?.data?.result, kind: 'error' },
        });
      }
    },
  });
};

export const updateUser = (data, messages, navigate) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_USER_DATA',
    payload: true,
  });
  apiPut({
    url: 'users',
    data,
    successHandler: () => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: messages.msg_success, kind: 'success' },
      });
      dispatch(userData(navigate));
      dispatch({
        type: 'SET_LOADING_USER_DATA',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
      dispatch({
        type: 'SET_LOADING_USER_DATA',
        payload: false,
      });
    },
  });
};

export const getDashboardData = (storeId, range) => (dispatch) => {
  dispatch({
    type: 'SET_DASHBOARD_LOADING',
    payload: true,
  });
  return apiGet({
    url: range ? `stores/${storeId}/stats?date_from=${range?.from}&date_to=${range?.to}` : `stores/${storeId}/stats`,
    successHandler: (data) => {
      dispatch({
        type: 'SET_DASHBOARD_DATA',
        payload: data?.result,
      });
      dispatch({
        type: 'SET_DASHBOARD_LOADING',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error.result?.message || error, kind: 'error' },
      });
      dispatch({
        type: 'SET_DASHBOARD_LOADING',
        payload: false,
      });
    },
  });
};
