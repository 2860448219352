import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress, Stack, IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getOrders } from '../../../redux/actions/ordersAction';
import { TextInputStyled } from '../../StepsCheckout/stepscheckout.styled';

function EnhancedTableToolbar(props) {
  const {
    isLoading,
    isAdmin,
    adminStore,
    setAdminStore,
  } = props;
  const { t } = useTranslation(['orders']);
  const dispatch = useDispatch();

  const handleOrdersByAdmin = () => {
    dispatch(getOrders(adminStore));
  };

  return (
    <Toolbar
      sx={{
        justifyContent: 'space-between',
        mt: 0.5,
        mb: 0.5,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography
          variant="h4"
          id="tableTitle"
          component="div"
        >
          {t('orders-title')}
        </Typography>
        {isLoading && <CircularProgress />}
      </Stack>
      <TextInputStyled
        sx={{ display: isAdmin ? 'flex' : 'none' }}
        type="number"
        size="small"
        label={t('orders-admin-input-label')}
        placeholder={t('orders-admin-input-ph')}
        value={adminStore}
        onChange={(e) => setAdminStore(e.target.value)}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton
              size="small"
              disabled={adminStore === ''}
              onClick={() => handleOrdersByAdmin()}
            >
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
