/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  FilterButton, FilterGrid, FilterStack, FiltersContainer,
} from './orders.styled';
import { TextInputStyled } from '../../StepsCheckout/stepscheckout.styled';
import { fDateCalendar, fDateShort } from '../../../utils/formatTime';
import { getOrders } from '../../../redux/actions/ordersAction';

function OrdersFilters({
  all, confirmed, cancelled, pending, adminStore, storeId, isLoading, resetPage,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['orders']);
  const filteredById = useSelector((state) => state.orders.filteredById);
  const locationIo = useSelector((state) => state.location.orderId);
  const [searchValue, setSearchValue] = useState(!filteredById ? locationIo : '');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [btnSelected, setBtnSelected] = useState(1);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (/\d/.test(value)) {
      dispatch({
        type: 'SET_FILTERED_ORDERS_BY_ID',
        payload: Number(value),
      });
    } else {
      dispatch({
        type: 'SET_FILTERED_ORDERS_BY_NAME',
        payload: value,
      });
    }
  };

  const handleStatusFilter = (btnId) => {
    setBtnSelected(btnId);
    switch (btnId) {
      case 1:
        dispatch({ type: 'RESET_FILTERED_ORDERS' });
        resetPage(0);
        break;
      case 2:
        dispatch({
          type: 'SET_FILTERED_ORDERS_BY_STATUS',
          payload: 'confirmed',
        });
        resetPage(0);
        break;
      case 3:
        dispatch({
          type: 'SET_FILTERED_ORDERS_BY_STATUS',
          payload: 'cancelled',
        });
        resetPage(0);
        break;
      case 4:
        dispatch({
          type: 'SET_FILTERED_ORDERS_BY_STATUS_PENDING',
        });
        resetPage(0);
        break;
      default:
        dispatch({ type: 'RESET_FILTERED_ORDERS' });
        break;
    }
  };

  const handleCustomDateRange = () => {
    if (adminStore !== '') {
      dispatch(getOrders(adminStore, {
        from: fDateCalendar(fromDate),
        to: fDateCalendar(toDate),
      }));
    } else {
      dispatch(getOrders(storeId, {
        from: fDateCalendar(fromDate),
        to: fDateCalendar(toDate),
      }));
    }
  };

  useEffect(() => {
    if (all > 0) {
      if (locationIo && !filteredById) {
        dispatch({
          type: 'SET_FILTERED_ORDERS_BY_ID',
          payload: locationIo,
        });
        dispatch({
          type: 'SET_FILTERED_BY_ID',
          payload: true,
        });
      }
    }
  }, [all]);

  return (
    <FiltersContainer>
      <FilterStack>
        <FilterGrid>
          <TextInputStyled
            fullWidth
            type="email"
            placeholder={t('orders-filter-search-ph')}
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ mr: 1 }} />,
            }}
          />
        </FilterGrid>
        <FilterGrid>
          <FilterButton
            size="small"
            variant="text"
            disableTouchRipple
            selected={btnSelected === 1}
            sum={all}
            afterid={1}
            onClick={() => handleStatusFilter(1)}
          >
            {t('orders-filter-all')}
          </FilterButton>
          <FilterButton
            size="small"
            variant="text"
            disableTouchRipple
            selected={btnSelected === 2}
            sum={confirmed}
            afterid={2}
            disabled={confirmed === 0 || all === confirmed}
            onClick={() => handleStatusFilter(2)}
          >
            {t('orders-filter-confirmed')}
          </FilterButton>
          <FilterButton
            size="small"
            variant="text"
            disableTouchRipple
            selected={btnSelected === 3}
            sum={cancelled}
            afterid={3}
            disabled={cancelled === 0 || all === cancelled}
            onClick={() => handleStatusFilter(3)}
          >
            {t('orders-filter-cancelled')}
          </FilterButton>
          <FilterButton
            size="small"
            variant="text"
            disableTouchRipple
            selected={btnSelected === 4}
            sum={pending}
            afterid={4}
            disabled={pending === 0 || all === pending}
            onClick={() => handleStatusFilter(4)}
          >
            {t('orders-filter-pending')}
          </FilterButton>
        </FilterGrid>
      </FilterStack>
      <Divider />
      <FilterStack>
        <FilterGrid>
          <DatePicker
            label={t('orders-filter-from')}
            value={fromDate}
            onChange={(e) => setFromDate(fDateShort(e))}
            renderInput={(props) => <TextField {...props} variant="outlined" />}
          />
          <DatePicker
            label={t('orders-filter-to')}
            value={toDate}
            onChange={(e) => setToDate(fDateShort(e))}
            renderInput={(props) => <TextField {...props} variant="outlined" />}
          />
          <Button
            size="large"
            variant="outlined"
            sx={{ borderRadius: '6rem', textTransform: 'none' }}
            disabled={!fromDate || !toDate || isLoading}
            onClick={() => handleCustomDateRange(fromDate)}
          >
            {t('orders-filter-search-cta')}
          </Button>
        </FilterGrid>
      </FilterStack>
    </FiltersContainer>
  );
}

export default OrdersFilters;
