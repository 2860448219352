import {
  Button,
  Grid, Stack, styled,
} from '@mui/material';

export const PaymentCardContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '15.5rem',
  marginLeft: '1rem',
  padding: '0.5rem 0.5rem 1rem 0.5rem',
  borderRadius: '1rem',
  backgroundColor: '#D8F6C4',
  '@media (max-width: 400px)': {
    marginLeft: 0,
    width: '100%',
  },
}));

export const CloseCardStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
}));

export const CardDataStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
  gap: '0.5rem',
}));

export const PaymentCardButton = styled(Button)(() => ({
  display: 'flex',
  padding: '0.75rem 1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#022219',
  color: '#9DEA6C',
  textTransform: 'none',
  borderRadius: '6rem',
  '&:hover': {
    backgroundColor: '#022219',
  },
}));

export const PaymentLinkContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

export const PaymentLinkForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  padding: '2rem 1rem',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
}));
