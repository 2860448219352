const initialState = {
  loadingDocuments: false,
  nationalDocuments: [],
  loadingLink: false,
  link: '',
  storeLinks: [],
};

export default function paymentLinkReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOADING_DOCUMENTS':
      return {
        ...state,
        loadingDocuments: action.payload,
      };
    case 'SET_NATIONAL_DOCUMENTS':
      return {
        ...state,
        nationalDocuments: action.payload,
      };
    case 'SET_LOADING_LINK':
      return {
        ...state,
        loadingLink: action.payload,
      };
    case 'SET_PAYMENT_LINK':
      return {
        ...state,
        link: action.payload,
      };
    case 'SET_STORE_LINKS':
      return {
        ...state,
        storeLinks: action.payload,
      };
    default:
      return state;
  }
}
