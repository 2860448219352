import React from 'react';
import {
  Button,
  IconButton,
  Stack, Tooltip, Typography, Zoom, styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import activeDot from '../../../assets/active_dot.svg';

const TooltipButton = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 'content',
  textAlign: 'center',
  alignSelf: 'end',
  borderRadius: '6.25rem',
  borderColor: '#9DEA6C',
  color: '#9DEA6C',
  '&:hover': {
    borderColor: '#9DEA6C',
    backgroundColor: '#9DEA6C',
    color: '#022219',
  },
}));

function TourTooltip({
  active, tooltipTitle, ctaText, tooltipAction, nextStep, skip,
}) {
  return (
    <Tooltip
      arrow
      placement="right"
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            display: 'flex',
            minWidth: '20rem',
            padding: '1rem 1rem 1rem 1.5rem',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: '#022219',
          },
        },
      }}
      TransitionComponent={Zoom}
      title={(
        <Stack gap={2}>
          <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={2}>
            <Typography variant="body2">
              {tooltipTitle}
            </Typography>
            <IconButton sx={{ display: skip ? 'flex' : 'none' }} size="small" onClick={() => skip()}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <TooltipButton variant="outlined" onClick={() => tooltipAction(nextStep)}>
            {ctaText}
          </TooltipButton>
        </Stack>
            )}
      open={active}
    >
      <img style={{ display: 'flex', visibility: active ? 'visible' : 'hidden' }} src={activeDot} alt="dot" />
    </Tooltip>
  );
}

export default TourTooltip;
