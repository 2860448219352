import {
  Button,
  Grid, IconButton, Stack, styled,
} from '@mui/material';

export const NavigationContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minWidth: '280px',
  minHeight: '100%',
  paddingTop: '2rem',
  // borderRight: '1px solid #D9D9D9',
  gap: '10rem',
  '@media (max-width: 900px)': {
    display: 'none',
  },
}));

export const NavigationStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minHeight: 'min-content',
  gap: '0.5rem',
}));

export const NavItemContainer = styled(Stack)(({ show }) => ({
  display: show ? 'flex' : 'none',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginLeft: '2rem',
  gap: '1rem',
  width: '100%',
}));

export const NavigationItem = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0.5rem',
  minWidth: '10.5rem',
  height: '3rem',
  padding: '1rem',
}));

export const IconButtonStyled = styled(IconButton)(() => ({
  display: 'none',
  '@media (max-width: 900px)': {
    display: 'flex',
  },
}));
