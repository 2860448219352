/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import {
  Chip, IconButton, styled, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { getOrders, refundOrder } from '../../../redux/actions/ordersAction';
import { fCurrency } from '../../../utils/formatNumber';
import { fDateShort } from '../../../utils/formatTime';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import OrdersPDF from './OrdersPDF';
import OrdersFilters from './OrdersFilters';
import TransitionComponent from '../TransitionComponent';

const TableCellStyled = styled(TableCell)(() => ({
  maxWidth: '10rem',
  minWidth: '10rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const RefundCancel = styled(Button)(() => ({
  borderRadius: '6rem',
  textTransform: 'none',
  minWidth: '6rem',
  maxHeight: '3rem',
}));

const RefundConfirm = styled(LoadingButton)(() => ({
  borderRadius: '6rem',
  textTransform: 'none',
  minWidth: '6rem',
  maxHeight: '3rem',
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

function OrdersList() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['orders']);
  const locationIs = useSelector((state) => state.location.storeId);
  const filteredById = useSelector((state) => state.orders.filteredById);

  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const stores = useSelector((state) => state.merchant.stores);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const loadingOrders = useSelector((state) => state.orders.loadingOrders);
  const orders = useSelector((state) => state.orders.ordersList);
  const filteredOrders = useSelector((state) => state.orders.filteredOrders);
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const isMerchant = stores.length > 0 && user.id_merchant !== null;
  const [allSum, setAllSum] = useState('');
  const [confirmedSum, setConfirmedSum] = useState('');
  const [cancelledSum, setCancelledSum] = useState('');
  const [pendingSum, setPendingSum] = useState('');

  const [adminStore, setAdminStore] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [refundOrderId, setRefundOrderId] = useState(null);

  const createData = (id, storeClient, date, total, idExternal, status) => {
    return {
      id,
      storeClient,
      date,
      total,
      idExternal,
      status,
    };
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefundRequest = (orderId) => {
    setRefundOrderId(orderId);
    setOpenRefundModal(true);
  };

  const handleRefundConfirm = () => {
    if (refundOrderId) {
      dispatch(refundOrder(refundOrderId, selectedStore?.id_store));
      setOpenRefundModal(false);
      setRefundOrderId(null);
    }
  };

  const handleRefundCancel = () => {
    setOpenRefundModal(false);
    setRefundOrderId(null);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(sortOrder, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [sortOrder, orderBy, page, rowsPerPage, rows],
  );

  const handleStatusLabel = (status) => {
    switch (status) {
      case 'confirmed': {
        return t('confirmed');
      }
      case 'cancelled': {
        return t('cancelled');
      }
      case 'pending': {
        return t('pending');
      }
      case 'processing': {
        return t('pending');
      }
      case 'refunded': {
        return t('refunded');
      }
      default:
        break;
    }
  };

  const handleChipColor = (status) => {
    switch (status) {
      case 'confirmed': {
        return 'success';
      }
      case 'cancelled': {
        return 'error';
      }
      case 'pending': {
        return 'info';
      }
      case 'processing': {
        return 'info';
      }
      case 'refunded': {
        return 'warning';
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (stores?.length > 0) {
      if (!filteredById && locationIs) {
        dispatch(getOrders(locationIs));
      } else {
        dispatch(getOrders(selectedStore?.id_store));
      }
    }
  }, [selectedStore]);

  useEffect(() => {
    if (orders?.length > 0) {
      setRows(
        filteredOrders?.map((o) => createData(
          o.id_order,
          isMerchant
            ? `${
              o.shopper
                ? `${o.shopper?.first_name} ${o.shopper?.last_name}`
                : `${o.guest_body?.shopper?.first_name} ${o.guest_body?.shopper?.last_name}`
            }`
            : o.store?.store_name,
          o.purchase_date,
          o.total_price,
          isMerchant ? o.id_external : o.store?.totalItems,
          o.status,
        )),
      );
      setAllSum(orders?.length);
      setConfirmedSum(orders?.filter((o) => o.status === 'confirmed').length);
      setCancelledSum(orders?.filter((o) => o.status === 'cancelled').length);
      setPendingSum(orders?.filter((o) => ['pending', 'processing'].includes(o.status)).length);
    }
    if (filteredOrders?.length < 13) {
      setPage(0);
    }
  }, [orders?.length, role, filteredOrders]);

  const handleMenuOpen = (event, orderId) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrderId(orderId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedOrderId(null);
  };

  return (
    <TransitionComponent>
      <Box sx={{ width: '100%', overflow: 'auto' }}>
        <EnhancedTableToolbar
          stores={stores}
          // role={role}
          isLoading={loadingOrders}
          isMerchant={isMerchant}
          isAdmin={user.is_admin}
          adminStore={adminStore}
          setAdminStore={setAdminStore}
        />
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            border: '1px solid #919EAB3D',
          }}
        >
          <OrdersFilters
            all={allSum}
            confirmed={confirmedSum}
            cancelled={cancelledSum}
            pending={pendingSum}
            adminStore={adminStore}
            storeId={selectedStore?.id_store}
            isLoading={loadingOrders}
            resetPage={setPage}
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <EnhancedTableHead order={sortOrder} orderBy={orderBy} onRequestSort={handleRequestSort} isMerchant={isMerchant} />
              <TableBody>
                {rows.length > 0 ? (
                  visibleRows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell padding="checkbox">{}</TableCell>
                        <TableCellStyled component="th" id={labelId} scope="row" padding="none">
                          {row.id}
                        </TableCellStyled>
                        <TableCellStyled align="left">{row.storeClient}</TableCellStyled>
                        <TableCellStyled align="left">{row.date ? fDateShort(row.date) : '-'}</TableCellStyled>
                        <TableCellStyled align="right">{fCurrency(row.total, selectedStore?.currency)}</TableCellStyled>
                        <TableCellStyled align="left">{row.idExternal || '-'}</TableCellStyled>
                        <TableCell align="right">
                          <Chip size="small" sx={{ color: '#fff' }} label={handleStatusLabel(row.status)} color={handleChipColor(row.status)} />
                        </TableCell>
                        <TableCellStyled align="center">
                          {row.status === 'confirmed' && (
                            <IconButton onClick={(event) => handleMenuOpen(event, row.id)}>
                              <MoreVertIcon />
                            </IconButton>
                          )}
                        </TableCellStyled>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell padding="checkbox">{}</TableCell>
                    <TableCellStyled component="th" scope="row" padding="none">
                      -
                    </TableCellStyled>
                    <TableCellStyled align="left">-</TableCellStyled>
                    <TableCellStyled align="left">-</TableCellStyled>
                    <TableCellStyled align="right">-</TableCellStyled>
                    <TableCellStyled align="left">-</TableCellStyled>
                    <TableCell align="right">-</TableCell>
                    <TableCellStyled align="center">-</TableCellStyled>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            sx={{ marginRight: '1rem' }}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <PDFDownloadLink document={<OrdersPDF order={orders.find((o) => o.id_order === selectedOrderId)} />} fileName={`Order #${selectedOrderId}`}>
          {() => (
            <Tooltip title="Download" placement="right">
              <MenuItem onClick={handleMenuClose}>
                <FileDownloadIcon fontSize="small" />
              </MenuItem>
            </Tooltip>
          )}
        </PDFDownloadLink>
        <Tooltip title="Refund" placement="right">
          <MenuItem onClick={() => {
            handleRefundRequest(selectedOrderId);
            handleMenuClose();
          }}
          >
            <KeyboardReturnRoundedIcon fontSize="small" />
          </MenuItem>
        </Tooltip>
      </Menu>

      <Dialog open={openRefundModal} onClose={handleRefundCancel}>
        <DialogTitle>Seguro desea reembolsar este pedido?</DialogTitle>
        <DialogContent>
          Se hará el reembolso del pedido con el id:
          {refundOrderId}
          . Esta acción no se puede deshacer.
        </DialogContent>
        <DialogActions>
          <RefundCancel onClick={handleRefundCancel}>{t('cancel')}</RefundCancel>
          <RefundConfirm onClick={handleRefundConfirm} color="primary">
            {t('confirm')}
          </RefundConfirm>
        </DialogActions>
      </Dialog>
    </TransitionComponent>
  );
}

export default OrdersList;
