/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Checkbox,
  CircularProgress,
  DialogContent,
  IconButton,
  Radio,
  Stack,
  Tooltip,
  Typography,
  Zoom,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  ConfigCta,
  ConfigLoadingCta,
  InfoCardContainer,
  IntActive,
  IntCardContainer,
  IntegrationLogo,
  TiendaNubeImage,
} from '../config.styled';
import { TextInputStyled } from '../../../StepsCheckout/stepscheckout.styled';
import { setIntegrationStatus, setNewWebhook, setTiendaNubeImage } from '../../../../redux/actions/storesActions';
import { ButtonStyled, DialogStyled, SwitchStyled } from '../../../Global.styled';

export const IntDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  padding: '1rem 2rem 2rem 2rem',
  gap: '1rem',
}));

export function InfoCard({ text, children }) {
  return (
    text ? (
      <InfoCardContainer>
        <InfoOutlinedIcon />
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text }} />
      </InfoCardContainer>
    ) : (
      <InfoCardContainer>
        <InfoOutlinedIcon />
        {children}
      </InfoCardContainer>
    )
  );
}

export default function IntegrationsCard({
  hasIntegration, status, logo, title, subtitle, link, modalKey, modalProps,
}) {
  const [modalState, setModalState] = useState(false);

  const createModal = (key) => {
    if (modalKey && modalProps) {
      switch (key) {
        case 'woo':
          return (
            <WooCommerceModal
              open={modalState}
              handleModal={setModalState}
              {...modalProps}
            />
          );
        case 'tn':
          return (
            <TiendaNubeModal
              open={modalState}
              handleModal={setModalState}
              {...modalProps}
            />
          );
        case 'api':
          return (
            <CustomIntegrationModal
              open={modalState}
              handleModal={setModalState}
              {...modalProps}
            />
          );
        default:
          return null;
      }
    }
  };

  const handleCta = () => {
    if (hasIntegration && !modalState) {
      setModalState(true);
    } else {
      window.open(link, '_blank');
    }
  };

  return (
    <>
      {createModal(modalKey)}
      <IntCardContainer active={status}>
        <IntActive hasint={modalKey === 'api' ? 0 : hasIntegration} active={status}>
          <Typography variant="body2">
            {status ? 'Activado' : 'Desactivado'}
          </Typography>
        </IntActive>
        <Stack direction="column" gap={1}>
          <IntegrationLogo
            src={logo}
            alt="Integration logo"
          />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography variant="body2">
            {subtitle}
          </Typography>
        </Stack>
        <ButtonStyled
          variant="contained"
          disabled={modalState}
          sx={{
            width: 'min-content',
            alignSelf: 'center',
            gap: '6px',
          }}
          onClick={() => handleCta()}
        >
          {hasIntegration || modalKey === 'api' ? 'Configurar' : 'Instalar'}
          {!hasIntegration && <OpenInNewIcon fontSize="xs" />}
        </ButtonStyled>
      </IntCardContainer>
    </>
  );
}

export function WooCommerceModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['configuration']);
  const isMobile = useMediaQuery('(max-width:780px)');

  const {
    open,
    handleModal,
    storeId,
    intData,
    loading,
  } = props;

  const handleIntStatusChange = () => {
    const action = intData?.status === 'active' ? 'deactivate' : 'activate';
    dispatch(setIntegrationStatus(storeId, intData?.id_integration, action));
  };

  return (
    <DialogStyled open={open} hideBackdrop>
      <IntDialogContent sx={{ minWidth: { sm: '38rem' } }}>
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <IconButton onClick={() => handleModal(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction="column" gap={1}>
          <Stack direction="row" alignItems="center" gap={2}>
            <IntegrationLogo
              src="https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/woocommerce-logo.svg"
              alt="WooCommerce logo"
            />
            {loading && <CircularProgress size="2rem" />}
          </Stack>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('integrations-modal-title')}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
          <Stack direction="row" gap={1} alignItems="center">
            <SwitchStyled
              checked={intData?.status === 'active'}
              disabled={!storeId && !intData}
              onChange={() => handleIntStatusChange()}
            />
          </Stack>
          <Tooltip
            arrow
            placement={isMobile ? 'bottom' : 'right'}
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  display: 'flex',
                  maxWidth: '14rem',
                  padding: '1rem 1rem 1rem 1.5rem',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  backgroundColor: '#022219',
                },
              },
            }}
            TransitionComponent={Zoom}
            title={(
              <Typography variant="caption" dangerouslySetInnerHTML={{ __html: t('integrations-woo-info') }} />
            )}
            open
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1,
              }}
            >
              {t('integrations-woo-modal-cta')}
              {!isMobile && <InfoOutlinedIcon fontSize="sm" />}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack direction="column" gap={1}>
          <ConfigCta
            variant="contained"
            sx={{ width: 'fit-content', mt: 2, marginTop: isMobile ? '6rem' : '1rem' }}
            onClick={() => window.open('https://wordpress.org/plugins/wava-payment/', '_blank')}
          >
            {t('config-download-plugin')}
          </ConfigCta>
        </Stack>
      </IntDialogContent>
    </DialogStyled>
  );
}

export function TiendaNubeModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['configuration']);
  const {
    open,
    handleModal,
    storeId,
    intData,
    loading,
    loadingImages,
    images,
  } = props;

  const [selectedImage, setSelectedImage] = useState(intData?.image?.id_image || null);
  const disableImageChanges = `${selectedImage}` === intData?.image?.id_image;

  const handleIntStatusChange = () => {
    const action = intData?.status === 'active' ? 'deactivate' : 'activate';
    dispatch(setIntegrationStatus(storeId, intData?.id_integration, action));
  };

  const handleSaveImage = () => {
    dispatch(setTiendaNubeImage(
      storeId,
      intData?.id_integration,
      {
        platform: 'tiendanube',
        id_image: selectedImage,
      },
    ));
  };

  return (
    <DialogStyled open={open} hideBackdrop>
      <IntDialogContent>
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <IconButton onClick={() => handleModal(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction="column" gap={1}>
          <IntegrationLogo
            src="https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/tiendanube-logo.svg"
            alt="Tiendanube logo"
          />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('integrations-modal-title')}
          </Typography>
        </Stack>
        <Stack direction="column" gap={2}>
          <Stack direction="row" gap={2} alignItems="center" py={1}>
            <SwitchStyled
              checked={intData?.status === 'active'}
              disabled={!storeId && !intData}
              onClick={() => handleIntStatusChange()}
            />
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {t('integrations-tn-action')}
            </Typography>
            {loading && <CircularProgress />}
          </Stack>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('integrations-tn-custom-title')}
          </Typography>
          <Typography variant="body2">
            {t('integrations-tn-custom-desc')}
          </Typography>
          <Stack
            direction="column"
            gap={0}
            my={2}
            sx={{ maxHeight: { sm: '24rem' } }}
            flexWrap="wrap"
          >
            {loadingImages && images?.length === 0
              ? <CircularProgress />
              : (images?.length > 0
                ? (images.map((i) => (
                  <Stack key={i.id_image} direction="row" alignItems="center" gap={1}>
                    <Radio
                      disabled={loadingImages}
                      checked={i.id_image === Number(selectedImage)}
                      onClick={() => setSelectedImage(i.id_image)}
                    />
                    <TiendaNubeImage
                      src={i.preview_image}
                      alt="Tiendanube button style"
                    />
                  </Stack>
                ))
                )
                : null
              )}
          </Stack>
          <Stack direction="row" gap={2} mt={2}>
            <ConfigCta
              disabled={!selectedImage || loadingImages || disableImageChanges}
              variant="outlined"
              onClick={() => setSelectedImage(intData?.image?.id_image || null)}
            >
              {t('config-cancel')}
            </ConfigCta>
            <ConfigLoadingCta
              disabled={!selectedImage || disableImageChanges}
              variant="contained"
              loading={loadingImages}
              onClick={() => handleSaveImage()}
            >
              {t('config-save')}
            </ConfigLoadingCta>
          </Stack>
        </Stack>
      </IntDialogContent>
    </DialogStyled>
  );
}

export function CustomIntegrationModal({
  open, handleModal, mk, storeId, loadingWh, webhooks,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['configuration']);
  const successWh = webhooks?.find((w) => w.type === 'success');
  const anyWh = webhooks?.find((w) => w.type === 'any');
  const cancelWh = webhooks?.find((w) => w.type === 'failure');

  const [webhook, setWebhook] = useState(successWh?.url || anyWh?.url || '');
  const [cancelWebhook, setCancelWebhook] = useState(cancelWh?.url || '');
  const [useSameWebhook, setUseSameWebhook] = useState(true);

  useEffect(() => {
    if (webhooks?.length > 0) {
      setWebhook(webhooks?.find((w) => w.type === 'success')?.url || webhooks?.find((w) => w.type === 'any')?.url);
      setCancelWebhook(webhooks?.find((w) => w.type === 'failure')?.url);
    }
  }, [webhooks]);

  const handleCopyData = (data) => {
    navigator.clipboard.writeText(data);
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: t('config-copy') || 'Copied to clipboard!', kind: 'success' },
    });
  };

  const handleSuccessWebhook = () => {
    if (webhook.length > 12) {
      dispatch(setNewWebhook(
        storeId,
        {
          type: 'any', // useSameWebhook ? 'both' : 'success',
          url: webhook,
          platform: 'webhook',
        },
        {
          success: t('config-wh-success'),
          error: t('config-wh-error'),
        },
      ));
    } else {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: t('config-wh-length') || 'Webhook must be at least 13 characters long', kind: 'warning' },
      });
    }
  };

  const handleCancelWebhook = () => {
    if (cancelWebhook.length > 12) {
      dispatch(setNewWebhook(
        storeId,
        {
          type: 'failure',
          url: cancelWebhook,
          platform: 'webhook',
        },
        {
          success: t('config-wh-success'),
          error: t('config-wh-error'),
        },
      ));
    } else {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: t('config-wh-length') || 'Webhook must be at least 13 characters long', kind: 'warning' },
      });
    }
  };

  return (
    <DialogStyled open={open} hideBackdrop>
      <IntDialogContent>
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <IconButton onClick={() => handleModal(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack direction="column" gap={2}>
          <IntegrationLogo
            src="https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/terminal-logo.svg"
            alt="Terminal logo"
          />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('integrations-modal-title')}
          </Typography>
        </Stack>
        <Stack direction="column" gap={1} mb={1}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('integrations-custom-key')}
          </Typography>
          <Typography variant="body2">
            {t('integrations-custom-key-desc')}
          </Typography>
          <TextInputStyled
            sx={{ maxWidth: { sm: '50%' }, mt: 2, mb: 2 }}
            disabled={!mk}
            value={mk || 'No disponible'}
            label={t('integrations-custom-key-label')}
            InputProps={{
              endAdornment:
                mk
                && (
                  <IconButton onClick={() => handleCopyData(mk)}>
                    <ContentCopyIcon color="primary" />
                  </IconButton>
                ),
            }}
          />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {t('integrations-custom-wh-title')}
          </Typography>
          <Typography variant="body2">
            {t('integrations-custom-wh-desc')}
          </Typography>
          <TextInputStyled
            sx={{ mt: 2 }}
            value={webhook || ''}
            placeholder={t('integrations-custom-wh-ph')}
            label={t('integrations-custom-wh-label')}
            onChange={(e) => setWebhook(e.target.value)}
            InputProps={{
              endAdornment:
                <ConfigLoadingCta
                  variant="contained"
                  disabled={webhook === ''}
                  loading={loadingWh}
                  onClick={() => handleSuccessWebhook()}
                >
                  {t('integrations-custom-wh-cta')}
                </ConfigLoadingCta>,
            }}
          />
          <Stack direction="row" gap={2} alignItems="center">
            <Checkbox
              checked={useSameWebhook}
              onClick={() => setUseSameWebhook(!useSameWebhook)}
            />
            <Typography variant="body2">
              {t('integrations-custom-wh-checkbox-2')}
            </Typography>
          </Stack>
          <TextInputStyled
            sx={{ my: 2 }}
            disabled={useSameWebhook}
            value={cancelWebhook || ''}
            placeholder={t('integrations-custom-wh-ph')}
            label={t('integrations-custom-wh-label-cancel')}
            onChange={(e) => setCancelWebhook(e.target.value)}
            InputProps={{
              endAdornment:
                <ConfigLoadingCta
                  variant="contained"
                  disabled={cancelWebhook === '' || useSameWebhook}
                  loading={loadingWh}
                  onClick={() => handleCancelWebhook()}
                >
                  {t('integrations-custom-wh-cta')}
                </ConfigLoadingCta>,
            }}
          />
          <InfoCard text={t('integrations-custom-info')} />
        </Stack>
      </IntDialogContent>
    </DialogStyled>
  );
}
