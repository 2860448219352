/* eslint-disable camelcase */
import { api } from '../../utils/api';
import {
  apiPost, apiGet, apiPatch, apiDelete,
} from '../../utils/ms_payments';

const api_key = process.env.REACT_APP_MS_PAYMENTS_API_KEY ? process.env.REACT_APP_MS_PAYMENTS_API_KEY : 'ABCDEFG';

const getToken = async (userData, dispatch, next) => {
  apiPost({
    url: 'auth',
    data: {
      id_shopper: userData.id_shopper,
      api_key,
    },
    successHandler: (data) => {
      document.cookie = `mspayToken=${data?.result?.token};max-age=86400; SameSite=None; Secure`;
      next(data?.result?.token);
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error.msg_error, kind: 'negative' },
      });
      return false;
    },
  });
};

export const getPayment = (userData) => (dispatch) => {
  getToken(userData, dispatch, (token) => {
    apiGet(
      {
        url: 'paymentMethods/',
        successHandler: (data) => {
          dispatch({
            type: 'SET_USER_DATA',
            payload: { payment_methods: [{ credit_cards: data?.result[0]?.credit_cards }] },
          });
        },
        errorHandler: (error) => {
          dispatch({
            type: 'SET_MESSAGE',
            payload: { text: error, kind: 'negative' },
          });
        },
      },
      token,
    );
  });
};

export const addPayment = (userData, ccData, messages, closeVGSForm) => (dispatch) => {
  const data = {
    payment_method_type: 'card',
    credit_card: ccData,
  };
  getToken(userData, dispatch, (token) => {
    apiPost(
      {
        url: 'paymentMethods',
        data,
        successHandler: () => {
          dispatch(getPayment(userData));
          dispatch({
            type: 'SET_MESSAGE',
            payload: { text: messages.msg_success, kind: 'positive' },
          });
          closeVGSForm(false);
        },
        errorHandler: (error) => {
          closeVGSForm(false);
          dispatch({
            type: 'SET_MESSAGE',
            payload: { text: error, kind: 'negative' },
          });
        },
      },
      token,
    );
  });
};

export const getTokenVGS = (userData, callback) => async (dispatch) => {
  dispatch({
    type: 'SET_LOADING_VGS_TOKEN',
    payload: true,
  });
  getToken(userData, dispatch, (token) => {
    apiPost(
      {
        url: 'auth/vgs',
        successHandler: (data) => {
          dispatch({
            type: 'SET_LOADING_VGS_TOKEN',
            payload: false,
          });
          callback(data?.result?.access_token);
        },
        errorHandler: (error) => {
          dispatch({
            type: 'SET_MESSAGE',
            payload: { text: error, kind: 'negative' },
          });
        },
      },
      token,
    );
  });
};

export const setDefault = (userData, ccData, messages) => (dispatch) => {
  const data = {
    payment_method_type: 'card',
    ...ccData,
  };
  getToken(userData, dispatch, (token) => {
    apiPatch({
      url: 'paymentMethods/',
      data,
      successHandler: () => {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: messages.msg_success, kind: 'success' },
        });
        dispatch(getPayment(userData));
      },
      errorHandler: () => {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: messages.msg_error, kind: 'error' },
        });
      },
    }, token);
  });
};

export const deleteCard = (
  userData,
  id_credit_card,
  messages,
  closeModal,
) => (dispatch) => {
  getToken(userData, dispatch, (token) => {
    apiDelete({
      url: `creditCards/${id_credit_card}`,
      successHandler: () => {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: messages.msg_success, kind: 'success' },
        });
        dispatch(getPayment(userData));
        closeModal(false);
      },
      errorHandler: (error) => {
        closeModal(false);
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: error, kind: 'error' },
        });
      },
    }, token);
  });
};

export const createPaymentLink = (data, storeId) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_LINK',
    payload: true,
  });
  dispatch({
    type: 'SET_PAYMENT_LINK',
    payload: '',
  });
  return api({
    url: `links/${storeId}`,
    data,
    successHandler: (res) => {
      dispatch({
        type: 'SET_PAYMENT_LINK',
        payload: res.result.link,
      });
      dispatch({
        type: 'SET_LOADING_LINK',
        payload: false,
      });
    },
    errorHandler: (error) => {
      if (error?.result === 'Max Links amount has been reached') {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'Max links capacity has been reached', kind: 'error' },
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'Link failed', kind: 'error' },
        });
      }
      dispatch({
        type: 'SET_LOADING_LINK',
        payload: false,
      });
    },
  });
};
