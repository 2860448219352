import { Grid, Stack, styled } from '@mui/material';

export const LoginContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
}));

export const SectionsContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100dvh',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
}));

export const WavaInfoSection = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F9FAFB',
  '@media (max-width: 1020px)': {
    display: 'none',
  },
}));

export const WavaInfoWrapper = styled(Grid)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',
  padding: '6rem 1rem 0rem 1rem',
  '@media (max-width: 768px)': {
    padding: '6rem 0rem 0rem 0rem',
  },
}));

export const InfoStepCircle = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #E1E4E3',
  borderRadius: '100%',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  aspectRatio: 1,
}));

export const WavaInfoStepper = styled(Grid)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

export const StepperStack = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const WavaInfoImage = styled('img')(({ active }) => ({
  width: active ? '30rem' : '1px',
  height: active ? '28rem' : '1px',
  opacity: active ? 1 : 0,
  alignSelf: 'flex-end',
  objectFit: 'contain',
  '@media (max-width: 1160px)': {
    height: active ? '24rem' : '1px',
  },
}));

export const LoginSection = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LoginWrapper = styled(Grid)(() => ({
  display: 'flex',
  maxWidth: '30rem',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '1rem',
  padding: '6rem 4rem',
}));

export const TextStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '0.25rem',
}));

export const LoginFooter = styled(Grid)(() => ({
  display: 'flex',
  width: '80%',
  gap: '0.5rem',
  marginBottom: '4rem',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

export const SelectWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  padding: '1rem',
  margin: '2rem 0rem',
  gap: '1rem',
  border: '1px solid #E1E4E3',
  borderRadius: '1rem',
}));

export const ImageBox = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '48px',
  maxWidth: '48px',
  minHeight: '48px',
  maxHeight: '48px',
  border: '1px solid #E1E4E3',
  borderRadius: '8px',
  borderStyle: 'dashed',
}));

export const StoreSelection = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '1rem',
}));

export const SuccessContainer = styled(Grid)(() => ({
  display: 'flex',
  maxWidth: '30rem',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  gap: '1rem',
  padding: '6rem 4rem',
}));
