/* eslint-disable react/jsx-indent */
import React from 'react';
import {
  CircularProgress,
  IconButton, Link, Stack, Tooltip, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  DialogContentStyled, DialogStyled,
} from './modal.styled';
import { TextInputStyled } from '../StepsCheckout/stepscheckout.styled';

function PaymentLinkModal({ open, handleModal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['modals']);
  const paymentLink = useSelector((state) => state.paymentLink.link);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(paymentLink);
    dispatch({
      type: 'SET_MESSAGE',
      payload: { text: t('modal-msg-copy-success') || 'Copied to clipboard!', kind: 'success' },
    });
  };

  const handleModalClose = () => {
    handleModal(false);
    dispatch({
      type: 'SET_PAYMENT_LINK',
      payload: '',
    });
  };

  return (
    <DialogStyled open={open} onClose={() => handleModalClose()} hideBackdrop>
      <DialogContentStyled sx={{
        gap: '0rem !important', maxWidth: '30rem', width: '30rem', overflow: 'hidden',
      }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">
            {t('modal-paymentlink-title')}
          </Typography>
          <IconButton onClick={() => handleModalClose()}>
            <Close />
          </IconButton>
        </Stack>
        {paymentLink !== ''
          ? (
            <TextInputStyled
              type="text"
              id="linkInput"
              label={t('modal-paymentlink-input')}
              value={paymentLink}
              InputLabelProps={{ shrink: true }}
              onClick={() => document.getElementById('linkInput').select()}
              fullWidth
              InputProps={{
                endAdornment:
                  <Tooltip title={t('modal-tooltip-copy')}>
                    <IconButton onClick={() => handleCopyClick()}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>,
              }}
            />
          ) : <CircularProgress />}
          <Link
            target="_blank"
            href={paymentLink}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              gap: '0.5rem',
              paddingTop: '1rem',
            }}
          >
            <Typography variant="body1">
              {t('modal-paymentlink-preview')}
            </Typography>
            <IconButton>
              <OpenInNewIcon />
            </IconButton>
          </Link>
      </DialogContentStyled>
    </DialogStyled>
  );
}

export default PaymentLinkModal;
