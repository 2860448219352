export const emailPattern = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

export const handleEmailRegex = (value) => {
  if (emailPattern.test(value)) {
    return true;
  }
  return false;
};

export const handleNumericRegex = (value, setAction) => {
  const numRegex = /^[0-9]*$/;
  if (numRegex.test(value)) {
    setAction(value);
  }
};

export const handleTextRegex = (value, setAction) => {
  const textRegex = /^[a-zA-ZÁ-ÿ_ ]*$/;
  if (textRegex.test(value)) {
    setAction(value);
  }
};
