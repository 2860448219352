import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Button, Typography, Container, Grid,
} from '@mui/material';
import PageNotFoundIllustration from '../assets/PageNotFoundIllustration';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Page404() {
  return (
    <Container>
      <ContentStyle>
        <Grid>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>
        </Grid>

        <Grid>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for.
            Perhaps you’ve mistyped the URL? Be sure to check
            your spelling.
          </Typography>
        </Grid>

        <Grid>
          <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </Grid>

        <Button to="/" size="large" variant="contained" sx={{ borderRadius: '6rem' }} component={RouterLink}>
          Go to Home
        </Button>
      </ContentStyle>
    </Container>
  );
}
