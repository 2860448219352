import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfigContainer, TabsContainer } from './config.styled';
import CustomTabPanel from './CustomTabPanel';
import General from './Tabs/General';
import Integrations from './Tabs/Integrations';
import Notifications from './Tabs/Notifications';
import TransitionComponent from '../TransitionComponent';
import PaymentMethods from './Tabs/PaymentMethods';

const tabHashMap = {
  '#general': 0,
  '#payment-methods': 1,
  '#integrations': 2,
  '#notifications': 3,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Config() {
  const { t } = useTranslation(['configuration']);
  const storeIntegrations = useSelector((state) => state.merchant.stores[0]?.integrations);
  const includesNiceEat = storeIntegrations?.some((int) => int.platform === 'niceeat');
  const [value, setValue] = useState(0);

  useEffect(() => {
    const fullHash = window.location.hash.toLowerCase();
    const hash = fullHash.split('?')[0]; // Extract hash without query params
    if (hash in tabHashMap) {
      setValue(tabHashMap[hash]);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const hashKey = Object.keys(tabHashMap).find((key) => tabHashMap[key] === newValue);
    if (hashKey) {
      // Preserve existing query parameters
      const currentHash = window.location.hash;
      const queryParams = currentHash.includes('?') ? currentHash.split('?')[1] : '';
      const newHash = queryParams ? `${hashKey}?${queryParams}` : hashKey;
      window.history.pushState(null, '', newHash);
    }
  };

  return (
    <TransitionComponent>
      <ConfigContainer>
        <Typography variant="h5">{t('config-title')}</Typography>
        <TabsContainer>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
            <Tab disableRipple label={t('config-tab-general')} {...a11yProps(0)} />
            <Tab disableRipple label={t('config-tab-payment-methods')} {...a11yProps(1)} />
            <Tab disableRipple disabled={includesNiceEat} label={t('config-tab-integrations')} {...a11yProps(2)} />
            <Tab disableRipple disabled={includesNiceEat} label={t('config-tab-notifications')} {...a11yProps(3)} />
          </Tabs>
        </TabsContainer>
        <CustomTabPanel value={value} index={0}>
          <General />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PaymentMethods />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Integrations changetab={setValue} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Notifications />
        </CustomTabPanel>
      </ConfigContainer>
    </TransitionComponent>
  );
}

export default Config;
