/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress, Skeleton, Stack, Typography, Button, Box,
} from '@mui/material';
import { PanelContainer, PanelSection } from '../config.styled';
import { getPaymentGateways, setGatewayCredentials } from '../../../../redux/actions/storesActions';
import { SwitchStyled } from '../../../Global.styled';

function GatewaySwitch({
  gatewayLogo, text, loading, isStripe, stripeStatus, stripeLink,
}) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" gap={2} alignItems="center">
        <SwitchStyled disabled checked={!isStripe || (isStripe && stripeStatus === 'active')} />
        {Array.isArray(gatewayLogo) ? (
          gatewayLogo.map((logo) => (
            <Box
              key={logo}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
              }}
            >
              <img src={logo} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
              height: '40px',
            }}
          >
            <img src={gatewayLogo || ''} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </Box>
        )}
        <Typography variant="body2" color="primary">
          {text}
        </Typography>
      </Stack>
      {isStripe && stripeStatus !== 'active' && (
        <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
          <Button
            variant="contained"
            size="small"
            href={stripeLink}
            target="_blank"
            rel="noopener noreferrer"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Connect Stripe'}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

const gatewayText = (gateway) => {
  switch (gateway) {
    case 'nequi':
      return 'Recibe pagos desde la aplicación de Nequi. Disponible solo para Colombia';
    case 'daviplata':
      return 'Recibe pagos desde la aplicación de Daviplata. Disponible solo para Colombia.';
    case 'card':
      return 'Recibe pagos desde tarjetas de crédito y débito. Disponible en todo el mundo.';
    case 'wallet':
      return 'Recibe pagos desde aplicaciones de pago como Nequi y Daviplata. Disponible solo para Colombia.';
    case 'stripe':
      return 'Recibe pagos a través de Stripe. Maneja múltiples medios de pago directo desde tu cuenta de Stripe.';
    default:
      return '';
  }
};

function PaymentMethods() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['configuration']);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const gateways = useSelector((state) => state.merchant.paymentGateways);
  const loadingGateways = useSelector((state) => state.merchant.loadingGateways);

  useEffect(() => {
    dispatch(getPaymentGateways(selectedStore?.id_store, selectedStore?.country_name));
  }, [selectedStore?.id_store, gateways?.length]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const connectedAccountId = urlParams.get('connectedAccountId');
    const refresh = urlParams.get('refresh');

    if (refresh === 'true') {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: 'Link caducado, por favor reintente', kind: 'warning' },
      });
    }

    if (connectedAccountId) {
      const stripeGateway = gateways.find((g) => g.gateway_type === 'stripe');
      if (stripeGateway && connectedAccountId === stripeGateway.accountId) {
        dispatch(setGatewayCredentials(selectedStore?.id_store, connectedAccountId, stripeGateway.id_payment_gateway));
      }
    }
  }, []);

  return (
    <PanelContainer>
      <PanelSection active={1} border sx={{ paddingTop: 2 }}>
        <Stack direction="row" gap={2}>
          <Typography variant="h6">{t('config-tab-payment-methods-title')}</Typography>
          {loadingGateways && <CircularProgress />}
        </Stack>
        <Typography variant="p">{t('config-tab-payment-methods-desc')}</Typography>
      </PanelSection>
      {selectedStore?.country_name === 'Mexico' ? (
        <PanelSection active={1} border>
          {gateways?.length > 0 ? (
            gateways?.map((g) => (
              <GatewaySwitch
                key={g.id_payment_gateway}
                gatewayLogo={g.gateway_logo}
                text={gatewayText(g.gateway_type)}
                loading={loadingGateways}
                isStripe={g.gateway_type === 'stripe'}
                stripeStatus={selectedStore?.gateways?.find((gw) => gw.id_payment_gateway === g.id_payment_gateway)?.status}
                stripeLink={g.stripeLink}
              />
            ))
          ) : (
            <Stack gap={4}>
              <Skeleton variant="rounded" height="3rem" animation="wave" />
              <Skeleton variant="rounded" height="3rem" animation="wave" />
              <Skeleton variant="rounded" height="3rem" animation="wave" />
            </Stack>
          )}
        </PanelSection>
      ) : (
        <PanelSection active={1} border>
          <GatewaySwitch
            key={999}
            gatewayLogo={gateways?.filter((g) => ['nequi', 'daviplata'].includes(g.gateway_type))?.map((g) => g.gateway_logo)}
            text={gatewayText('wallet')}
            loading={loadingGateways}
          />
        </PanelSection>
      )}
    </PanelContainer>
  );
}

export default PaymentMethods;
