const initialState = {
  text: '',
  kind: '',
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_MESSAGE':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAR_MESSAGE':
      return initialState;
    default: return state;
  }
}
