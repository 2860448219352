import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import NoTransactions from './NoTransactions';
import {
  CardData, CardMethod, ChartCard, ChartContainer,
} from '../dashboard.styled';
import { fCurrency } from '../../../utils/formatNumber';

function DashPieChart() {
  const dashboardData = useSelector((state) => state.dashboard);
  const activeChart = Boolean(dashboardData?.payment_methods);
  const { t } = useTranslation(['dashboard']);
  const colorsArray = ['#83BF6E', '#4D72F5', '#FDF07E'];
  const pieData = activeChart
  && dashboardData?.payment_methods?.map((method, index) => (
    { key: index, value: method.revenue }
  ));

  const getPaymentName = (methodName) => {
    switch (methodName) {
      case 'credit_card': {
        return t('pie-method-card');
      }
      case 'nequi': {
        return t('pie-method-nequi');
      }
      case 'wire': {
        return t('pie-method-bank');
      }
      case 'cash': {
        return t('pie-method-cash');
      }
      default:
        break;
    }
  };

  return (
    <ChartContainer>
      <Typography variant="h4">
        {t('piechart-title')}
      </Typography>
      <ChartCard active={activeChart ? 1 : 0}>
        {activeChart
          ? (
            <>
              <PieChart
                colors={['#83BF6E', '#4D72F5', '#FDF07E']}
                series={[
                  {
                    data: pieData || [{ value: 1, color: '#D9D9D9' }],
                    innerRadius: 70,
                    outerRadius: 100,
                    paddingAngle: 0,
                    startAngle: -90,
                    endAngle: 360,
                  },
                ]}
                height={200}
                sx={{
                  width: '10rem',
                  position: 'absolute',
                  left: 50,
                }}
              />
              <CardData>
                {activeChart && dashboardData?.payment_methods?.map((method, index) => (
                  <CardMethod
                    key={method.type}
                    sx={index !== pieData.length - 1
                      ? { borderBottom: '1px solid #D9D9D9' }
                      : { border: 'none' }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <circle cx="8" cy="8" r="8" fill={colorsArray[index]} />
                      </svg>
                      <Typography variant="body2">
                        {getPaymentName(method.type)}
                      </Typography>
                    </Stack>
                    <Typography variant="body2" sx={{ color: '#484848' }}>
                      {fCurrency(method.revenue)}
                    </Typography>
                  </CardMethod>
                ))}
              </CardData>
            </>
          )
          : <NoTransactions /> }
      </ChartCard>
    </ChartContainer>
  );
}

export default DashPieChart;
