import {
  Grid, Stack, Typography, styled,
} from '@mui/material';

export const MainContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  minHeight: '100%',
  padding: '3.5rem 2.5rem 2rem 2.5rem',
  overflow: 'hidden',
  whiteSpace: 'none',
  gap: '2rem',
  '@media (max-width: 400px)': {
    padding: '2rem 1.25rem 1rem 1.25rem',
  },
}));

export const HeadingsContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '0.5rem',
  '@media (max-width: 400px)': {
    gap: 0,
  },
}));

export const PriceSpan = styled('span')(() => ({
  color: '#2A85FF',
}));

export const Carousel = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  gap: '1rem',
  flexWrap: 'wrap',
  whiteSpace: 'none',
  overflow: 'hidden',
  overflowY: 'hidden',
  overflowX: 'scroll',
  WebkitOverflowScrolling: 'auto',
  scrollBehavior: 'auto',
  scrollSnapType: 'x mandatory',
  ' &::-webkit-scrollbar': {
    width: 0,
  },
  '@media (max-width: 900px)': {
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'scroll',
    overflowY: 'hidden',
    overflowX: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    WebkitOverflowScrolling: 'auto',
    scrollBehavior: 'auto',
    scrollSnapType: 'x mandatory',
  },
}));

export const DashCard = styled(Grid)(() => ({
  scrollSnapAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minWidth: '16.2rem',
  padding: '1rem',
  gap: '0.5rem',
  backgroundColor: '#F0F2F1',
  borderRadius: '16px',
  '@media (max-width: 400px)': {
    gap: 0,
  },
}));

export const DashCardStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

export const DashCardChip = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '50px',
  padding: '4px 8px 4px 8px',
  gap: '4px',
}));

export const DashChartsContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',
  gap: '1rem',
  '@media (max-width: 800px)': {
    flexDirection: 'column',
    gap: '2rem',
  },
}));

export const ChartContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  maxWidth: '22rem',
  minWidth: '22rem',
  gap: '1rem',
  '@media (max-width: 800px)': {
    maxWidth: '100%',
    minWidth: '100%',
  },
}));

export const ChartCard = styled(Grid)(({ active }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: active ? 'flex-start' : 'center',
  alignItems: active ? 'flex-start' : 'center',
  border: '1px solid #D9D9D9',
  borderRadius: '16px',
  maxWidth: '22rem',
  minWidth: '22rem',
  maxHeight: '452px',
  minHeight: '452px',
  padding: '2rem 1.25rem 1rem 1.25rem',
  gap: '1rem',
  '@media (max-width: 800px)': {
    maxWidth: '100%',
    minWidth: '100%',
  },
}));

export const CardData = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
}));

export const CardMethod = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  width: '100%',
  gap: '1rem',
  '@media (max-width: 400px)': {
    gap: 0,
  },
}));

export const TypographyStyled = styled(Typography)(({ active }) => ({
  color: active ? '#484848' : '#A0A0A0',
}));

export const NoDataStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: '1rem',
}));

// More Sold Components

export const MoreSoldContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  maxWidth: '45.125rem',
  minWidth: '22rem',
  gap: '1rem',
  '@media (max-width: 800px)': {
    maxWidth: '100%',
    minWidth: '100%',
  },
}));

export const MoreSoldCard = styled(Grid)(({ active }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: active ? 'flex-start' : 'center',
  alignItems: active ? 'flex-start' : 'center',
  border: '1px solid #D9D9D9',
  borderRadius: '16px',
  overflow: 'scroll',
  width: '100%',
  maxWidth: '45.125rem',
  minWidth: '22rem',
  maxHeight: '452px',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  minHeight: '452px',
  padding: '2rem 1.25rem 1rem 1.25rem',
  gap: '1rem',
  '@media (max-width: 800px)': {
    maxWidth: '100%',
    minWidth: '100%',
  },
}));
