/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import NoTransactions from './NoTransactions';
// import { fDateShort } from '../../../utils/formatTime';
import { ChartCard, ChartContainer } from '../dashboard.styled';

function DashLineChart() {
  const { t } = useTranslation(['dashboard']);
  const dashboardData = useSelector((state) => state.dashboard);
  const activeChart = dashboardData?.daily_revenue?.length > 1;
  const [revenueLabels, setRevenueLabels] = useState(
    activeChart && dashboardData?.daily_revenue?.map((r) => r?.date),
  );
  const [revenueTotals, setRevenueTotals] = useState(
    activeChart && dashboardData?.daily_revenue?.map((r) => r?.revenue),
  );
  const [dates, setDates] = useState();

  const uData = [0, 50, 100];
  const xLabels = ['Data 1', 'Data 2', 'Data 3'];

  useEffect(() => {
    if (dashboardData?.daily_revenue) {
      const dailyFilterArr = [
        dashboardData?.daily_revenue[0]?.date,
        dashboardData?.daily_revenue[dashboardData?.daily_revenue?.length - 1]?.date,
      ];
      setDates(dailyFilterArr);
      setRevenueTotals(activeChart && dashboardData?.daily_revenue?.map((r) => r?.revenue));
      setRevenueLabels(activeChart && dashboardData?.daily_revenue?.map((r) => r?.date));
    }
  }, [dashboardData?.daily_revenue]);

  return (
    <ChartContainer>
      <Typography variant="h4">
        {t('linechart-title')}
      </Typography>
      <ChartCard sx={{ gap: 0 }} active={activeChart ? 1 : 0}>
        {activeChart
          ? (
            <>
              <LineChart
                xAxis={[{ scaleType: 'point', data: revenueLabels || xLabels }]}
                series={[
                  { data: revenueTotals || uData, label: 'Revenue' },
                ]}
                sx={{
                  height: '26rem !important',
                  width: '100%',
                  marginTop: '-150px',
                  '& .MuiChartsLegend-root': {
                    display: 'none',
                  },
                  '.MuiLineElement-root': {
                    stroke: '#022219',
                    strokeWidth: 2,
                  },
                  '.MuiMarkElement-root': {
                    stroke: '#022219',
                    fill: '#9DEA6C',
                    strokeWidth: 2,
                  },
                }}
              />
              <Stack
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '1rem',
                  width: '100%',
                  paddingLeft: '1rem',
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="2" viewBox="0 0 33 2" fill="none">
                  <path d="M0 1H32" stroke="#022219" strokeWidth="2" strokeLinecap="round" />
                </svg>
                <Typography variant="body2">
                  {`• ${dates ? dates[0] : ''} - ${dates ? dates[1] : ''}`}
                </Typography>
              </Stack>
            </>
          )
          : (
            <NoTransactions />
          )}
      </ChartCard>
    </ChartContainer>
  );
}

export default DashLineChart;
