import React from 'react';

function CardRevenue({ active }) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? '#191A1D' : '#A0A0A0'} />
      <g clipPath="url(#clip0_203_633)">
        <path d="M21.489 17.0536C21.3765 16.5271 20.6235 16.5271 20.511 17.0536L19.317 22.6276C19.1732 23.299 18.8034 23.9007 18.2694 24.3323C17.7355 24.7639 17.0696 24.9992 16.383 24.9991H15C14.448 24.9991 13.9995 24.5521 13.9995 23.9986C13.9995 23.4451 14.4465 22.9981 15 22.9981H16.383C16.854 22.9981 17.262 22.6681 17.361 22.2076L18.555 16.6336C19.119 13.9981 22.8795 13.9981 23.4435 16.6336L26.5095 30.9436C26.622 31.4701 27.375 31.4701 27.4875 30.9436L28.6815 25.3696C28.8254 24.6983 29.1951 24.0966 29.7291 23.665C30.2631 23.2334 30.9289 22.998 31.6155 22.9981H33C33.552 22.9981 34.0005 23.4451 34.0005 23.9986C34.0005 24.5521 33.5535 24.9991 33 24.9991H31.617C31.146 24.9991 30.738 25.3291 30.639 25.7896L29.445 31.3636C28.881 33.9991 25.1205 33.9991 24.5565 31.3636L21.489 17.0536Z" fill={active ? '#9DEA6C' : '#E1E4E3'} />
      </g>
      <defs>
        <clipPath id="clip0_203_633">
          <rect width="24" height="24" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CardRevenue;
