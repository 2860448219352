import {
  Grid, Stack, styled,
} from '@mui/material';

export const LoggedLayout = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  minHeight: '100vh',
}));

export const LoggedNavbar = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  alignItems: 'center',
  width: '100%',
  minHeight: '4.7rem',
  backgroundColor: 'transparent',
  borderBottom: '1px solid #D9D9D9',
  '@media (max-width: 400px)': {
    minHeight: '64px',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}));

export const LogoStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  '@media (max-width: 400px)': {
    gap: '0.5rem',
  },
}));

export const NavDataStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1rem',
  '@media (max-width: 900px)': {
    display: 'none',
  },
}));

export const WavaLogo = styled('img')(() => ({
  width: 'min-content',
  '@media (max-width: 400px)': {
    width: 87,
  },
}));

export const LoggedContainer = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  maxWidth: '100vw',
  minHeight: '100%',
}));
