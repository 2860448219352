import {
  styled, Grid, Stack, Skeleton,
} from '@mui/material';

export const Container = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
}));

export const Nav = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  alignItems: 'center',
  width: '100%',
  minHeight: '4.7rem',
  backgroundColor: 'transparent',
  borderBottom: '1px solid #D9D9D9',
  '@media (max-width: 400px)': {
    minHeight: '64px',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}));

export const ContentContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const SideNav = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minWidth: '280px',
  minHeight: '100%',
  paddingTop: '2rem',
  gap: '10rem',
  '@media (max-width: 900px)': {
    display: 'none',
  },
}));

export const SideStack = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minHeight: 'min-content',
  gap: '1rem',
}));

export const SideItemSkeleton = styled(Skeleton)(() => ({
  display: 'flex',
  flexDirection: 'row',
  minWidth: '10.5rem',
  height: '3rem',
  marginLeft: '2rem',
}));

export const Content = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  height: '100%',
  padding: '4rem 2rem',
  gap: '2rem',
}));
