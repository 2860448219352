import { LoadingButton } from '@mui/lab';
import {
  Grid,
  TextField,
  Typography,
  Switch,
  alpha,
  styled,
} from '@mui/material';

export const CheckoutContainer = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
}));

export const StoreLogoContainer = styled(Grid)(({ mobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  margin: mobile ? '0.5rem 0rem' : 0,
}));

export const StoreLogo = styled('img')(() => ({
  display: 'block',
  aspectRatio: '16/9',
  objectFit: 'contain',
  height: '5rem',
}));

export const StepsSection = styled(Grid)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
}));

export const StepsContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  gap: '0.3rem',
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
}));

export const StepText = styled(Typography)(({ stepvalue }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontWeight: stepvalue ? 600 : 500,
  color: '#0A0B1F',
  whiteSpace: 'nowrap',
  '@media (max-width: 320px)': {
    fontSize: '0.7rem',
  },
}));

export const DataContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
}));

export const StepContinueButton = styled(LoadingButton)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  textTransform: 'none',
  width: '100%',
  height: '3rem',
  borderRadius: '100px',
}));

export const SwitchStyled = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: '#022219',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#9DEA6C',
    '&:hover': {
      backgroundColor: alpha('#022219', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#022219',
  },
}));

// FORMS

export const FormStyled = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const TextInputStyled = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CF2C2C',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#B12323',
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: '#484848',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#191A1D',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    color: '#484848',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D9D9D9',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#A4A4A4',
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    borderColor: '#D9D9D9',
    backgroundColor: '#F0F2F1',
    '&:hover fieldset': {
      borderColor: '#D9D9D9',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#191A1D',
    borderRadius: '8px',
    borderWidth: '1.5px',
  },
  '& :-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 1000px rgba(255,255,255) inset',
  },
}));

export const SelectInputStyled = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CF2C2C',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#B12323',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    color: '#484848',
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: '#B12323',
  },
  display: 'flex',
  flex: 1,
  width: '100%',
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#191A1D',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#D9D9D9',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#A4A4A4',
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    borderColor: '#D9D9D9',
    backgroundColor: '#F0F2F1',
    '&:hover fieldset': {
      borderColor: '#D9D9D9',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#191A1D',
    borderRadius: '8px',
    borderWidth: '1.5px',
  },
}));

// CODESTEP STYLED

export const CodeStepContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  paddingTop: '2rem',
  gap: '1rem',
}));

export const CodeStepCard = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  border: '1px solid #E6E6E6;',
  borderRadius: '20px',
  padding: '2rem',
  gap: '1rem',
}));

export const SpanStyled = styled('span')(({ link, bold }) => ({
  color: link && '#4217E0',
  textDecoration: link && 'underline',
  cursor: link && 'pointer',
  fontWeight: bold && 'bold',
}));

export const OpacityContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  gap: '1rem',
}));

export const CodeLoaderContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  paddingTop: '0.5rem',
}));

export const ArrowContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '0.5rem',
  width: '100%',
}));
