const initialState = {
  // orders: {},
  confirmation: {},
  loadingOrders: false,
  loadingDiscount: false,
  loadingShipping: false,
  ordersList: [],
  filteredOrders: [],
  filteredById: false,
  hasDefaultPayment: false,
  payment_method: {
    id_payment_gateway: 0,
    gateway_name: '',
  },
};

export default function ordersReducer(state = initialState, action) {
  let productIndex = '';

  if (action?.payload?.id_product) {
    productIndex = state.store.products.findIndex(
      (prod) => prod.id_product === action.payload.id_product,
    );
  }
  switch (action.type) {
    case 'SET_ORDER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_ORDER_CONFIRMED':
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          ...action.payload,
        },
      };
    case 'SET_ORDER_FAILED':
      return {
        ...state,
        confirmation: {
          status: 'failed',
          message: action.payload,
        },
      };
    case 'PLACE_ORDER':
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          loading: action.payload,
        },
      };
    case 'CLEAR_ORDER':
      return {
        ...initialState,
      };
    case 'SET_ORDERS_LIST':
      return {
        ...state,
        ordersList: action.payload,
      };
    case 'SET_FILTERED_ORDERS':
      return {
        ...state,
        filteredOrders: action.payload,
      };
    case 'SET_FILTERED_BY_ID':
      return {
        ...state,
        filteredById: action.payload,
      };
    case 'SET_FILTERED_ORDERS_BY_NAME':
      return {
        ...state,
        filteredOrders: state.ordersList.filter((o) => (o.shopper
          ? o.shopper?.first_name?.concat(' ', o.shopper?.last_name).toLowerCase().includes(action.payload.toLowerCase())
          : o.guest_body?.shopper?.first_name?.concat(' ', o.guest_body?.shopper?.last_name).toLowerCase().includes(action.payload.toLowerCase()))),
      };
    case 'SET_FILTERED_ORDERS_BY_ID':
      return {
        ...state,
        filteredOrders: state.ordersList.filter((o) => o.id_order === action.payload),
      };
    case 'SET_FILTERED_ORDERS_BY_STATUS':
      return {
        ...state,
        filteredOrders: state.ordersList.filter((o) => o.status === action.payload),
      };
    case 'SET_FILTERED_ORDERS_BY_STATUS_PENDING':
      return {
        ...state,
        filteredOrders: state.ordersList.filter((o) => ['pending', 'processing'].includes(o.status)),
      };
    case 'RESET_FILTERED_ORDERS':
      return {
        ...state,
        filteredOrders: state.ordersList,
      };
    case 'SET_ORDER_LOADING':
      return {
        ...state,
        loadingOrders: action.payload,
      };
    case 'SET_DISCOUNT_LOADING':
      return {
        ...state,
        loadingDiscount: action.payload,
      };
    case 'SET_SHIPPING_LOADING':
      return {
        ...state,
        loadingShipping: action.payload,
      };
    case 'SET_PRODUCT_QUANTITY_LOADING':
      state.store.products[productIndex] = {
        ...state.store.products[productIndex],
        loadingQuantity: action.payload.status,
      };
      return {
        ...state,
      };
    case 'SET_PRODUCT_DELETE_LOADING':
      state.store.products[productIndex] = {
        ...state.store.products[productIndex],
        loadingProduct: action.payload.status,
      };
      return {
        ...state,
      };
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        payment_method: action.payload,
      };
    case 'SET_PAYMENT_RESET':
      return {
        ...state,
        payment_method: {
          id_payment_gateway: 0,
          gateway_name: '',
        },
      };
    case 'SET_HAS_DEFAULT_PAYMENT':
      return {
        ...state,
        hasDefaultPayment: action.payload,
      };
    case 'SET_SHIPPING_METHODS':
      return {
        ...state,
        store: {
          ...state.store,
          shipping_methods: action.payload,
        },
      };
    case 'SET_SHIPPING_METHOD_EXTERNAL':
      return {
        ...state,
        store: {
          ...state.store,
          id_shipping_method_external: null,
        },
      };
    case 'RESET_ORDERS':
      return initialState;
    default:
      return state;
  }
}
