import { apiGet } from '../../utils/api';

export const getAllowedDocuments = (countryCode) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_USER_DATA',
    payload: true,
  });
  return apiGet({
    url: `nationalDocuments/${countryCode}`,
    successHandler: (data) => {
      dispatch({
        type: 'SET_ALLOWED_DOCUMENTS',
        payload: data?.result,
      });
      dispatch({
        type: 'SET_LOADING_USER_DATA',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_LOADING_USER_DATA',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
    },
  });
};

export const getNationalDocuments = (countryCode) => (dispatch) => {
  dispatch({
    type: 'SET_LOADING_DOCUMENTS',
    payload: true,
  });
  return apiGet({
    url: `nationalDocuments/${countryCode}`,
    successHandler: (data) => {
      dispatch({
        type: 'SET_NATIONAL_DOCUMENTS',
        payload: data?.result,
      });
      dispatch({
        type: 'SET_LOADING_DOCUMENTS',
        payload: false,
      });
    },
    errorHandler: (error) => {
      dispatch({
        type: 'SET_LOADING_DOCUMENTS',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { text: error, kind: 'error' },
      });
    },
  });
};
