/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { login } from '../../redux/actions/authAction';
import {
  LoginWrapper,
  TextStack,
} from './Login.styled';
import { FormStyled, TextInputStyled } from '../StepsCheckout/stepscheckout.styled';
import { emailPattern, handleEmailRegex } from '../../utils/regex';

function Login() {
  const { t } = useTranslation(['login']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const loadingState = useSelector((state) => state.loading);

  const [email, setEmail] = useState(location?.state?.e || '');
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (user?.isAuthenticated) {
      navigate('/selectstore');
    }
  }, [user.isAuthenticated]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (handleEmailRegex(email) && email !== '') {
      dispatch(login({ email }, navigate, t('login-err')));
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleLoginSignup = () => {
    dispatch({ type: 'SET_LOGIN_ONBOARD' });
    navigate('/signup');
  };

  const checkEmailError = () => {
    if (email === '' || !handleEmailRegex(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  return (
    <LoginWrapper>
      <Typography variant="h3">
        {t('login-title')}
      </Typography>
      <TextStack>
        <Typography variant="body1">
          {t('login-subtitle')}
        </Typography>
        <Typography
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          variant="body1"
          onClick={() => handleLoginSignup()}
        >
          {t('login-subtitle-cta')}
        </Typography>
      </TextStack>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        {t('login-desc')}
      </Typography>
      <FormStyled onSubmit={(e) => onSubmit(e)}>
        <TextInputStyled
          type="email"
          label={t('label')}
          placeholder={t('placeholder')}
          value={email}
          error={emailError}
          helperText={emailError && t('email-required')}
          pattern={emailPattern}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Stack alignItems="center" sx={{ mt: 3 }}>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            sx={{ borderRadius: '6rem', textTransform: 'none' }}
            fullWidth
            loading={loadingState}
            onClick={() => checkEmailError()}
          >
            {t('login-cta')}
          </LoadingButton>
        </Stack>
      </FormStyled>
    </LoginWrapper>
  );
}

export default Login;
