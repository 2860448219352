import {
  Grid, IconButton, MenuItem, styled,
} from '@mui/material';

export const MenuItemContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  width: '100%',
  minHeight: '100vh',
}));

export const MenuItemStyled = styled(MenuItem)(({ show }) => ({
  display: show ? 'flex' : 'none',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  margin: '0.5rem',
  gap: '0.5rem',
}));

export const IconButtonStyled = styled(IconButton)(() => ({
  display: 'none',
  '@media (max-width: 900px)': {
    display: 'flex',
  },
}));
