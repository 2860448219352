const initialState = 'Shopper';

export default function rolesReducer(state = initialState, action) {
  if (action.payload && ['Merchant', 'Shopper'].includes(action.payload)) return action.payload;
  switch (action.type) {
    case 'SWITCH_ROLE':
      if (state === 'Merchant') { return 'Shopper'; }
      return 'Merchant';
    case 'RESET_ROLE':
      return initialState;
    default:
      return state;
  }
}
