/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  Grid, Typography, Stack, CircularProgress,
} from '@mui/material';
import Iconify from '../Iconify/Iconify';
import AuthCode from './AuthCode';
import { loginCode, loginResendCode } from '../../redux/actions/authAction';
import { LoginWrapper } from './Login.styled';

function Code() {
  const { t } = useTranslation(['login']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const loadingState = useSelector((state) => state.loading);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const codeError = false;
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (!user?.email) {
      navigate('/login');
    }
    if (user?.isAuthenticated) {
      navigate('/selectstore');
    }
  }, [user.isAuthenticated, user.email]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      timer > 0 && setTimer(timer - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const handleOnChange = (code) => {
    if (code.length === 4) {
      dispatch(
        loginCode(
          { email: user.email, logincode: code },
          { msg_error: t('code-notification-negative') },
          navigate,
        ),
      );
    }
  };

  const onResend = () => {
    setIsSubmitting(true);
    setTimer(60);
    dispatch(loginResendCode(
      { email: user.email },
      {
        msg_positive: t('code-resend-notification-positive'),
        msg_negative: t('code-resend-notification-negative'),
      },
    ));
    setIsSubmitting(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <LoginWrapper>
      <Grid item>
        <Iconify
          icon="eva:arrow-back-outline"
          size={22}
          onClick={() => handleBack()}
          style={{
            cursor: 'pointer', width: '22px', height: '22px', fontWeight: 700,
          }}
        />
      </Grid>
      <Typography variant="h3">
        {t('code-title')}
      </Typography>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t('code-subtitle', { user_email: user.email }) }} />
      <AuthCode onChange={handleOnChange} error={codeError} isAuth={user.isAuthenticated} />
      {codeError && (
        <Typography
          align="center"
          color="error"
          variant="body2"
        >
          {t('code-notification-negative')}
        </Typography>
      )}
      {loadingState && <CircularProgress sx={{ alignSelf: 'center' }} />}
      <Stack width="100%" alignItems="center" mt={2}>
        <Typography variant="body2" component="p" paragraph align="center" style={{ color: '#2B2D42', margin: '1rem 0' }}>
          {t('code-reminder')}
        </Typography>
        <LoadingButton
          fullWidth
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          loading={isSubmitting}
          disabled={timer > 0 || user.isAuthenticated}
          onClick={() => onResend()}
          sx={{ borderRadius: '6rem', textTransform: 'none' }}
        >
          {t('code-cta') + (timer ? ` 00:${timer < 10 ? `0${timer}` : timer}` : '')}
        </LoadingButton>
      </Stack>
    </LoginWrapper>
  );
}

export default Code;
