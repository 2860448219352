/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-indent */
import AWS from 'aws-sdk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import {
  ConfigCta,
  ConfigLoadingCta,
  LogoContainer,
  LogoImage,
  PanelContainer,
  PanelSection,
} from '../config.styled';
import { TextInputStyled } from '../../../StepsCheckout/stepscheckout.styled';
import { editStoreConfig, setStoreLogo } from '../../../../redux/actions/storesActions';

function General() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['configuration']);
  const user = useSelector((state) => state.user);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const loadingStores = useSelector((state) => state.merchant.loadingStores);
  const loadingStoreLogo = useSelector((state) => state.merchant.loadingStoreLogo);
  const storeLogo = selectedStore?.logo_image || 'https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg';
  const [storeName, setStoreName] = useState(selectedStore?.store_name);
  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState(false);

  const env = process.env.REACT_APP_ENVIRONMENT;
  const s3k = process.env.REACT_APP_S3_K;
  const s3sk = process.env.REACT_APP_S3_SK;
  const S3_BUCKET = 'wava-stores';
  const REGION = 'us-east-1';

  AWS.config.update({
    accessKeyId: s3k,
    secretAccessKey: s3sk,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const handleStoreNameChange = () => {
    if (storeName.length > 1 && storeName !== selectedStore?.store_name) {
      dispatch(editStoreConfig(
        selectedStore?.id_store,
        { store_name: storeName?.trim() },
        {
          success: t('config-edit-success'),
          error: t('config-edit-error'),
        },
      ));
    }
  };

  const handleCancelChanges = (setlogoSuccess) => {
    if (setlogoSuccess) {
      document.getElementById('storeLogo').value = '';
      setFile(null);
    } else {
      document.getElementById('storeLogo').value = '';
      setImgPreview(null);
      setFile(null);
    }
  };

  const uploadFile = async () => {
    dispatch({
      type: 'SET_LOADING_STORE_LOGO',
      payload: true,
    });
    const params = {
      Bucket: S3_BUCKET,
      Key: `${env}/${selectedStore?.id_store}/${file.name}`,
      Body: file,
    };

    const upload = s3
      .putObject(params)
      .on('httpUploadProgress', (e) => {
        console.log(`Uploading logo ${parseInt((e.loaded * 100) / e.total, 10)}%`);
      })
      .promise();

    try {
      await upload.then(() => {
        dispatch(setStoreLogo(
          selectedStore?.id_store,
          {
            logo_image: `https://${S3_BUCKET}.s3.amazonaws.com/${env}/${selectedStore?.id_store}/${file.name}`,
          },
          {
            success: t('config-tab-general-store-logo-success'),
            error: t('config-tab-general-store-logo-err'),
          },
          handleCancelChanges,
        ));
      });
    } catch {
      dispatch({
        type: 'SET_LOADING_STORE_LOGO',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: t('config-tab-general-store-logo-err') || 'Error updating store logo',
          kind: 'error',
        },
      });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    }
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    setStoreName(selectedStore?.store_name);
  }, [selectedStore]);

  return (
    <PanelContainer>
      <PanelSection active={1} border sx={{ paddingTop: 2 }}>
        <Typography variant="h6">
          {t('config-tab-general-email-title')}
        </Typography>
        <TextInputStyled
          sx={{ width: '50%' }}
          value={user.email}
          label={t('config-tab-general-email-label')}
          disabled
        />
      </PanelSection>
      <PanelSection active={1} border>
        <Typography variant="h6">
          {t('config-tab-general-storename')}
        </Typography>
        <TextInputStyled
          sx={{ width: '50%' }}
          value={storeName}
          onChange={(e) => setStoreName(e.target.value)}
          label={t('config-tab-general-storename-label')}
        />
        <Stack direction="row" gap={2}>
          <ConfigCta
            disabled={storeName === selectedStore?.store_name}
            variant="outlined"
            onClick={() => setStoreName(selectedStore?.store_name)}
          >
            {t('config-cancel')}
          </ConfigCta>
          <ConfigLoadingCta
            disabled={storeName?.length < 2 || storeName === selectedStore?.store_name}
            variant="contained"
            onClick={() => handleStoreNameChange()}
            loading={loadingStores}
          >
            {t('config-save')}
          </ConfigLoadingCta>
        </Stack>
      </PanelSection>
      <PanelSection active={1}>
        <Typography variant="h6">
          {t('config-tab-general-store-logo')}
        </Typography>
        <Typography variant="p">
          {t('config-tab-general-store-logo-info')}
        </Typography>
        <Stack direction="row" gap={2} alignItems="center">
          <LogoContainer>
            <LogoImage src={imgPreview || storeLogo} alt="Store logo" />
          </LogoContainer>
          <input
            type="file"
            id="storeLogo"
            accept="image/*,.png"
            onChange={(e) => handleFileChange(e)}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <ConfigCta
            variant="outlined"
            disabled={!file || loadingStoreLogo}
            onClick={() => handleCancelChanges()}
          >
            {t('config-cancel')}
          </ConfigCta>
          <ConfigLoadingCta
            variant="contained"
            disabled={!file}
            loading={loadingStoreLogo}
            onClick={() => uploadFile()}
          >
            {t('config-save')}
          </ConfigLoadingCta>
        </Stack>
      </PanelSection>
    </PanelContainer>
  );
}

export default General;
