import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CardDataStack, CloseCardStack, PaymentCardButton, PaymentCardContainer,
} from './paymentlink.styled';

function PaymentLinkCard() {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const [openCard, setOpenCard] = useState(true);

  return (
    <PaymentCardContainer sx={openCard ? { } : { display: 'none' }}>
      <CloseCardStack>
        <IconButton onClick={() => setOpenCard(false)}>
          <CloseIcon size="sm" />
        </IconButton>
      </CloseCardStack>
      <CardDataStack>
        <Typography variant="h6">
          {t('payment-card-title')}
        </Typography>
        <Typography variant="body2">
          {t('payment-card-text')}
        </Typography>
        <PaymentCardButton onClick={() => navigate('/paymentLink')}>
          <Typography variant="body2">
            {t('payment-card-btn-label')}
          </Typography>
        </PaymentCardButton>
      </CardDataStack>
    </PaymentCardContainer>
  );
}

export default PaymentLinkCard;
