import React from 'react';

function CardTicket({ active }) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? '#191A1D' : '#A0A0A0'} />
      <path d="M32.25 15.375H15.75C15.2527 15.375 14.7758 15.5725 14.4242 15.9242C14.0725 16.2758 13.875 16.7527 13.875 17.25V31.5C13.875 31.6918 13.9241 31.8804 14.0175 32.0479C14.1109 32.2154 14.2456 32.3562 14.4088 32.457C14.5719 32.5578 14.7581 32.6152 14.9497 32.6237C15.1413 32.6323 15.3319 32.5918 15.5034 32.5059L18 31.2581L20.4966 32.5059C20.6529 32.5841 20.8252 32.6249 21 32.6249C21.1748 32.6249 21.3471 32.5841 21.5034 32.5059L24 31.2581L26.4966 32.5059C26.6529 32.5841 26.8252 32.6249 27 32.6249C27.1748 32.6249 27.3471 32.5841 27.5034 32.5059L30 31.2581L32.4966 32.5059C32.6681 32.5918 32.8587 32.6323 33.0503 32.6237C33.2419 32.6152 33.4281 32.5578 33.5912 32.457C33.7544 32.3562 33.8891 32.2154 33.9825 32.0479C34.0759 31.8804 34.125 31.6918 34.125 31.5V17.25C34.125 16.7527 33.9275 16.2758 33.5758 15.9242C33.2242 15.5725 32.7473 15.375 32.25 15.375ZM31.875 29.6794L30.5034 28.9941C30.3471 28.9159 30.1748 28.8751 30 28.8751C29.8252 28.8751 29.6529 28.9159 29.4966 28.9941L27 30.2419L24.5034 28.9941C24.3471 28.9159 24.1748 28.8751 24 28.8751C23.8252 28.8751 23.6529 28.9159 23.4966 28.9941L21 30.2419L18.5034 28.9941C18.3471 28.9159 18.1748 28.8751 18 28.8751C17.8252 28.8751 17.6529 28.9159 17.4966 28.9941L16.125 29.6794V17.625H31.875V29.6794Z" fill={active ? '#FEF5A5' : '#E1E4E3'} />
      <path d="M26.8235 24.5735C26.8235 25.1352 26.6004 25.6738 26.2033 26.0709C25.8061 26.4681 25.2675 26.6912 24.7059 26.6912V26.9559C24.7059 27.1665 24.6222 27.3685 24.4733 27.5174C24.3244 27.6663 24.1224 27.75 23.9118 27.75C23.7012 27.75 23.4992 27.6663 23.3502 27.5174C23.2013 27.3685 23.1176 27.1665 23.1176 26.9559V26.6912H22.3235C22.1129 26.6912 21.9109 26.6075 21.762 26.4586C21.6131 26.3097 21.5294 26.1077 21.5294 25.8971C21.5294 25.6864 21.6131 25.4845 21.762 25.3355C21.9109 25.1866 22.1129 25.1029 22.3235 25.1029H24.7059C24.8463 25.1029 24.9809 25.0472 25.0802 24.9479C25.1795 24.8486 25.2353 24.7139 25.2353 24.5735C25.2353 24.4331 25.1795 24.2985 25.0802 24.1992C24.9809 24.0999 24.8463 24.0441 24.7059 24.0441H23.1176C22.556 24.0441 22.0174 23.821 21.6202 23.4239C21.2231 23.0267 21 22.4881 21 21.9265C21 21.3648 21.2231 20.8262 21.6202 20.4291C22.0174 20.0319 22.556 19.8088 23.1176 19.8088V19.5441C23.1176 19.3335 23.2013 19.1315 23.3502 18.9826C23.4992 18.8337 23.7012 18.75 23.9118 18.75C24.1224 18.75 24.3244 18.8337 24.4733 18.9826C24.6222 19.1315 24.7059 19.3335 24.7059 19.5441V19.8088H25.5C25.7106 19.8088 25.9126 19.8925 26.0615 20.0414C26.2105 20.1903 26.2941 20.3923 26.2941 20.6029C26.2941 20.8136 26.2105 21.0155 26.0615 21.1645C25.9126 21.3134 25.7106 21.3971 25.5 21.3971H23.1176C22.9772 21.3971 22.8426 21.4528 22.7433 21.5521C22.644 21.6514 22.5882 21.7861 22.5882 21.9265C22.5882 22.0669 22.644 22.2015 22.7433 22.3008C22.8426 22.4001 22.9772 22.4559 23.1176 22.4559H24.7059C25.2675 22.4559 25.8061 22.679 26.2033 23.0761C26.6004 23.4733 26.8235 24.0119 26.8235 24.5735Z" fill={active ? '#FEF5A5' : '#E1E4E3'} />
    </svg>
  );
}

export default CardTicket;
