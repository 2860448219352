import React from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogContentStyled, DialogStyled, ModalStack } from './modal.styled';
import { setTourData } from '../../redux/actions/storesActions';

export default function WelcomeTourModal({ open }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['modals']);
  const store = useSelector((state) => state.merchant.stores);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const merchantConfig = store[0]?.config;
  const tourComplete = merchantConfig?.tour_complete;

  const handleTourStart = () => {
    dispatch({
      type: 'SET_WELCOME_TOUR',
      payload: {
        tour_complete: false,
        tour_step: 1,
      },
    });
    if (!tourComplete) {
      dispatch(setTourData(
        selectedStore?.id_store,
        {
          config: {
            ...merchantConfig,
            tour_complete: false,
            tour_step: 1,
          },
        },
      ));
    }
  };

  const handleSkipTour = () => {
    dispatch({
      type: 'SET_WELCOME_TOUR',
      payload: {
        tour_complete: true,
        tour_step: 5,
      },
    });
    dispatch(setTourData(
      selectedStore?.id_store,
      {
        config: {
          ...merchantConfig,
          tour_complete: true,
          tour_step: 5,
        },
      },
    ));
  };

  return (
    <DialogStyled open={open} hideBackdrop>
      <DialogContentStyled
        sx={{
          minWidth: { xs: '100%', sm: '32rem' },
          padding: '1rem 1rem 2rem 1rem',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <img
          src="https://wava-assets.s3.amazonaws.com/wava-dashboard/intro-tour/intro-modal.webp"
          alt="Welcome"
          style={{ borderRadius: '1rem' }}
        />
        <ModalStack>
          <Typography variant="h5">
            {t('modal-welcome-title')}
          </Typography>
          <Typography variant="body2">
            {t('modal-welcome-desc')}
          </Typography>
        </ModalStack>
        <ModalStack sx={{ flexDirection: 'row' }}>
          <Button
            sx={{ borderRadius: '6.25rem', textTransform: 'none', alignSelf: 'center' }}
            variant="contained"
            onClick={() => handleTourStart()}
          >
            {t('modal-welcome-cta')}
          </Button>
          <Button
            size="small"
            sx={{
              position: 'absolute',
              right: '1rem',
              fontWeight: 400,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            variant="text"
            onClick={() => handleSkipTour()}
          >
            {t('modal-welcome-skip')}
          </Button>
        </ModalStack>
      </DialogContentStyled>
    </DialogStyled>
  );
}
