const initialState = false;

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOADING_FALSE':
      return false;
    case 'SET_LOADING_TRUE':
      return true;
    default: return state;
  }
}
