import React from 'react';
import { motion } from 'framer-motion';

function TransitionComponent({ children }) {
  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}

export default TransitionComponent;
