import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

function EnhancedTableHead(props) {
  const { t } = useTranslation(['orders']);
  const {
    order, orderBy, onRequestSort, isMerchant,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'id',
      align: 'left',
      disablePadding: true,
      label: t('orders-th-id'),
      sort: true,
    },
    {
      id: 'storeClient',
      align: 'left',
      disablePadding: false,
      label: isMerchant ? t('orders-th-shopper') : t('orders-th-store'),
      sort: false,
    },
    {
      id: 'date',
      align: 'left',
      disablePadding: false,
      label: t('orders-th-date'),
      sort: true,
    },
    {
      id: 'total',
      align: 'right',
      disablePadding: false,
      label: t('orders-th-total'),
      sort: true,
    },
    {
      id: 'idExternal',
      align: 'left',
      disablePadding: false,
      label: isMerchant ? t('orders-th-id-ext') : t('orders-th-qty'),
      sort: false,
    },
    {
      id: 'status',
      align: 'right',
      disablePadding: false,
      label: t('orders-th-status'),
      sort: false,
    },
    {
      id: 'actions',
      align: 'center',
      disablePadding: false,
      label: t('orders-th-actions'),
      sort: false,
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ maxWidth: '10rem', minWidth: '10rem' }}
          >
            {headCell.sort
              ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id && headCell.sort ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )
              : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
