import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import {
  NavItemContainer, NavigationContainer, NavigationItem, NavigationStack,
} from './navigation.styled';
import PaymentLinkCard from '../PaymentLink/PaymentLinkCard';
import { TypographyStyled } from '../../Dashboard/dashboard.styled';
import ConfigIcon from '../../../assets/dashboard/ConfigIcon';
import DashboardIcon from '../../../assets/dashboard/DashboardIcon';
import PaymentLinkIcon from '../../../assets/dashboard/PaymentLinkIcon';
import ProfileIcon from '../../../assets/dashboard/ProfileIcon';
import OrdersListIcon from '../../../assets/dashboard/OrdersListIcon';
import DocsIcon from '../../../assets/dashboard/DocsIcon';
import TourTooltip from '../Tour/TourTooltip';
import { setTourData } from '../../../redux/actions/storesActions';

function Navigation() {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:900px)');
  const user = useSelector((state) => state.user);
  const selectedStore = useSelector((state) => state.merchant.selectedStore);
  const merchantConfig = selectedStore?.config;
  const tourStep = useSelector((state) => state.merchant.welcomeTour.tour_step);
  const tourComplete = useSelector((state) => state.merchant.welcomeTour.tour_complete);
  const isMerchant = user.id_merchant !== null;

  const location = useLocation();
  const profilePath = location?.pathname === '/' || location?.pathname === '/profile';
  const dashboardPath = location?.pathname === '/dashboard';
  const paymentPath = location?.pathname === '/paymentLink';
  const linksPath = location?.pathname === '/storeLinks';
  const ordersPath = location?.pathname === '/orders';
  const configPath = location?.pathname === '/config';

  const handleTourStep = (step) => {
    if (!tourComplete) {
      dispatch({
        type: 'SET_WELCOME_TOUR',
        payload: {
          tour_complete: false,
          tour_step: step,
        },
      });
      dispatch(setTourData(
        selectedStore?.id_store,
        {
          config: {
            ...merchantConfig,
            tour_complete: false,
            tour_step: step,
          },
        },
      ));
    }
  };

  const handleCompleteTour = (step) => {
    dispatch({
      type: 'SET_WELCOME_TOUR',
      payload: {
        tour_complete: true,
        tour_step: step,
      },
    });
    dispatch(setTourData(
      selectedStore?.id_store,
      {
        config: {
          ...merchantConfig,
          tour_complete: true,
          tour_step: step,
        },
      },
    ));
  };

  return (
    <NavigationContainer>
      <NavigationStack>
        <NavItemContainer show={isMerchant ? 1 : 0}>
          <NavigationItem onClick={() => navigate('/dashboard')}>
            <DashboardIcon active={dashboardPath} />
            <TypographyStyled
              variant="body1"
              active={dashboardPath ? 1 : 0}
              sx={dashboardPath ? { fontWeight: 600 } : {}}
            >
              {t('navigation-dashboard')}
            </TypographyStyled>
          </NavigationItem>
        </NavItemContainer>
        <NavItemContainer show={isMerchant ? 1 : 0}>
          <NavigationItem onClick={() => navigate('/paymentLink')}>
            <PaymentLinkIcon active={paymentPath} />
            <TypographyStyled
              variant="body1"
              active={paymentPath ? 1 : 0}
              sx={paymentPath ? { fontWeight: 600 } : {}}
            >
              {t('navigation-payment-link')}
            </TypographyStyled>
          </NavigationItem>
          <TourTooltip
            active={tourStep === 1 && !isMobile}
            tooltipTitle={t('tour-step-1')}
            ctaText={t('tour-step-continue')}
            tooltipAction={handleTourStep}
            nextStep={2}
            skip={handleCompleteTour}
          />
        </NavItemContainer>
        <NavItemContainer show={isMerchant ? 1 : 0}>
          <NavigationItem onClick={() => navigate('/storeLinks')}>
            <FormatListBulletedIcon style={linksPath ? { color: '#484848' } : { color: '#A0A0A0' }} />
            <TypographyStyled
              variant="body1"
              active={linksPath ? 1 : 0}
              sx={linksPath ? { fontWeight: 600 } : {}}
            >
              {t('navigation-store-links')}
            </TypographyStyled>
          </NavigationItem>
        </NavItemContainer>
        <NavItemContainer show={isMerchant ? 0 : 1}>
          <NavigationItem onClick={() => navigate('/profile')}>
            <ProfileIcon active={profilePath} />
            <TypographyStyled
              variant="body1"
              active={profilePath ? 1 : 0}
              sx={profilePath ? { fontWeight: 600 } : {}}
            >
              {t('navigation-profile')}
            </TypographyStyled>
          </NavigationItem>
        </NavItemContainer>
        <NavItemContainer show={1}>
          <NavigationItem onClick={() => navigate('/orders')}>
            <OrdersListIcon active={ordersPath} />
            <TypographyStyled
              variant="body1"
              active={ordersPath ? 1 : 0}
              sx={ordersPath ? { fontWeight: 600 } : {}}
            >
              {t('navigation-orders')}
            </TypographyStyled>
          </NavigationItem>
          <TourTooltip
            active={tourStep === 2 && !isMobile}
            tooltipTitle={t('tour-step-2')}
            ctaText={t('tour-step-continue')}
            tooltipAction={handleTourStep}
            // Payouts section (step 3) is pending.
            nextStep={4}
            skip={handleCompleteTour}
          />
        </NavItemContainer>
        <NavItemContainer show={isMerchant ? 1 : 0}>
          <NavigationItem onClick={() => navigate('/config')}>
            <ConfigIcon active={configPath} />
            <TypographyStyled
              variant="body1"
              active={configPath ? 1 : 0}
              sx={configPath ? { fontWeight: 600 } : {}}
            >
              {t('navigation-config')}
            </TypographyStyled>
          </NavigationItem>
          <TourTooltip
            active={tourStep === 4 && !isMobile}
            tooltipTitle={t('tour-step-4')}
            ctaText={t('tour-step-end')}
            // This step should end tour
            tooltipAction={handleCompleteTour}
            nextStep={5}
          />
        </NavItemContainer>
        <NavItemContainer show={isMerchant ? 1 : 0}>
          <NavigationItem onClick={() => window.open('https://www.notion.so/wava/3d91f531f641426fa4817d84689855ff?v=b852bf9938014ab09b6b2c243a5eb3aa', '_blank')}>
            <DocsIcon />
            <TypographyStyled variant="body1">
              {t('navigation-docs')}
            </TypographyStyled>
          </NavigationItem>
        </NavItemContainer>
      </NavigationStack>
      {isMerchant && <PaymentLinkCard />}
    </NavigationContainer>
  );
}

export default Navigation;
