import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/actions/authAction';

export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  axios.interceptors.request.use(
    (config) => {
      config.headers['x-app'] = 'wava';
      config.headers['Content-Type'] = 'application/json';
      config.withCredentials = true;
      config.timeout = 200000;
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (res) => {
      if (localStorage && res.headers.geo) {
        localStorage.setItem('geo', res.headers.geo);
        return res;
      }
      return res;
    },
    (error) => {
      if (error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
        console.log(`A timeout happend on url ${error.config.url}`);
        dispatch({
          type: 'SET_MESSAGE',
          payload: { text: 'Something happened', kind: 'error' },
        });
        Promise.reject(error.response);
      } else if (error?.response?.status === 401) {
        dispatch(logout(navigate));
        return Promise.reject(error?.response);
      } else {
        return Promise.reject(error);
      }
    },
  );
  return (
    children
  );
}
