const initialState = {
  loadingDashboard: false,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_DASHBOARD_DATA':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_DASHBOARD_LOADING':
      return {
        ...state,
        loadingDashboard: action.payload,
      };
    default:
      return state;
  }
}
