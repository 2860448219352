import { LoadingButton } from '@mui/lab';
import {
  Button, Dialog, DialogContent, Grid, styled,
} from '@mui/material';

export const DialogStyled = styled(Dialog)(() => ({
  background: 'rgba(22, 28, 36, 0.48)',
}));

export const DialogContentStyled = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'scroll',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  minWidth: '23rem',
  maxWidth: '23rem',
  padding: '1rem 2rem 2rem 2rem',
  gap: '2rem',
  '@media (max-width: 440px)': {
    minWidth: '20rem',
    maxWidth: '20rem',
    gap: '1rem',
    padding: '0.5rem 1.25rem 1.25rem 1.25rem',
  },
  '@media (max-width: 370px)': {
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
  },
}));

export const ModalStack = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: '0.75rem',
}));

export const ModalLoadingButton = styled(LoadingButton)(() => ({
  borderRadius: '6rem',
  padding: '0.75rem',
  backgroundColor: '#CF2C2C',
  '&:hover': {
    backgroundColor: '#d34141',
  },
}));

export const ModalCancelButton = styled(Button)(() => ({
  borderRadius: '6rem',
  padding: '0.75rem',
  border: '1px solid #A4A4A4',
  color: '#484848',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
}));
