import React from 'react';

function DocsIcon({ active }) {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.6025 6.338L12.3525 0.338C12.244 0.28022 12.1229 0.25 12 0.25C11.8771 0.25 11.756 0.28022 11.6475 0.338L0.39751 6.338C0.277514 6.40195 0.17716 6.49732 0.107185 6.6139C0.0372091 6.73049 0.000244141 6.8639 0.000244141 6.99988C0.000244141 7.13585 0.0372091 7.26926 0.107185 7.38585C0.17716 7.50243 0.277514 7.5978 0.39751 7.66175L3.00001 9.05019V13.5896C2.99923 13.958 3.13481 14.3136 3.38064 14.588C4.60876 15.9558 7.36032 18.2499 12 18.2499C13.5384 18.2626 15.0653 17.9839 16.5 17.4286V20.4999C16.5 20.6988 16.579 20.8896 16.7197 21.0302C16.8603 21.1709 17.0511 21.2499 17.25 21.2499C17.4489 21.2499 17.6397 21.1709 17.7803 21.0302C17.921 20.8896 18 20.6988 18 20.4999V16.7039C18.978 16.1394 19.8618 15.4254 20.6194 14.588C20.8652 14.3136 21.0008 13.958 21 13.5896V9.05019L23.6025 7.66175C23.7225 7.5978 23.8229 7.50243 23.8928 7.38585C23.9628 7.26926 23.9998 7.13585 23.9998 6.99988C23.9998 6.8639 23.9628 6.73049 23.8928 6.6139C23.8229 6.49732 23.7225 6.40195 23.6025 6.338ZM12 16.7499C7.94345 16.7499 5.55751 14.768 4.50001 13.5896V9.84987L11.6475 13.6618C11.756 13.7195 11.8771 13.7498 12 13.7498C12.1229 13.7498 12.244 13.7195 12.3525 13.6618L16.5 11.4502V15.7946C15.3188 16.3458 13.83 16.7499 12 16.7499ZM19.5 13.5858C19.0504 14.0847 18.5474 14.5327 18 14.9218V10.6496L19.5 9.84987V13.5858ZM17.625 9.1505L17.6044 9.13831L12.3544 6.338C12.1792 6.24853 11.9759 6.23145 11.7883 6.29044C11.6007 6.34943 11.4437 6.47976 11.3513 6.65337C11.2588 6.82697 11.2382 7.02994 11.294 7.21855C11.3498 7.40717 11.4774 7.56633 11.6494 7.66175L16.0313 9.99988L12 12.1496L2.34376 6.99988L12 1.85019L21.6563 6.99988L17.625 9.1505Z"
        fill={active ? '#484848' : '#A0A0A0'}
      />
    </svg>

  );
}

export default DocsIcon;
